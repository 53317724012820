import { useState, useContext, useEffect } from 'react'
import { AuthConsumer, AuthContext } from './AuthContext'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify' 

export default function Passreset(){

	const [email, setEmail] = useState(null)

	const con = useContext(AuthContext)
	const navigate = useNavigate()

	useEffect(() => {
		con.testUser()

		if(con.isAuth){
			navigate(-2)
		}
	}, [])
	
	const tryReset = () => {

		if (!email){
			toast.warn('Must fill all fields!')
		}
		else{
			con.passreset(email)
		}
	}

	return(
		<div className="space-y-6 sm:p-12 p-4 max-w-7xl m-auto">
			<img src="" alt=""/>

			<div className="bg-white shadow p-12 sm:rounded-lg sm:p-6">
		        <div className="md:grid md:grid-cols-3 md:gap-6">
		          <div className="md:col-span-1">
		            <h3 className="text-lg font-medium leading-6 text-gray-900">Reset your splus.cloud password</h3>
		            <p className="mt-1 text-sm text-gray-500">If you don't remember your email you may contact our team.</p>
		          </div>
		          <div className="mt-5 md:mt-0 md:col-span-2">
		            <form action="#" method="POST">
		              <div className="grid grid-cols-6 gap-6">
			                <div className="col-span-6 sm:col-span-6">
			                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
			                    Email
			                  </label>
			                  <input
			                  	onChange={(e) => setEmail(e.target.value)}
			                    type="text"
			                    name="email"
			                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
			                  />
			                </div>
			                <div className="flex">
					                <button
					                	onClick = {() => tryReset()}
							            type="button"
							            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							          >
							            Submit
							         </button>
							    </div>
						</div>

						
		            </form>
		          </div>
		        </div>
		      </div>
	    </div>
	)
}