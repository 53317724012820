
import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify'

import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';

import { getToken } from '../auth/token';
import SpinLoad from "../common/Spinning"

import 'chart.js/auto';

const make_body_request = (ra = null, dec = null, name = null) => {
    var data
    if (ra && dec) {
        data = {
            ra: ra,
            dec: dec,
        }
    }
    else if (name) {
        data = {
            name: name
        }
    }
    else {
        return null
    }

    return data
}

export default function SkySearch() {
    
    const [ra, setRA] = useState('');
    const [dec, setDEC] = useState('');
    const [nameRA, setNAMERA] = useState(null);
    const [nameDEC, setNAMEDEC] = useState(null);

    const [fitsBand, setFitsBand] = useState('R')
    
    const [name, setName] = useState('');

	const [imgSrc, setImgSrc] = useState()

    const [dualData, setDualData] = useState(null);
    const [singleData, setSingleData] = useState(null);
    const [psfData, setPsfData] = useState(null);

    const [chartData, setChartData] = useState({});
    const [fetchingIm, setFetchingIm] = useState(false);

    const [loading, setLoading] = useState(false);

    const [R, setR] = useState('I')
    const [G, setG] = useState('R')
    const [B, setB] = useState('G')

    const [size, setSize] = useState('500')
    const [stretch, setStretch] = useState('3')
    const [option, setOption] = useState(1)

    const [req, setReq] = useState(false)

    const handleSearch = () => {
        setLoading(true)
        setChartData({})
        setNAMERA(null)
        setNAMEDEC(null)
        setDualData(null)
        setSingleData(null)
        setPsfData(null)
        setOption(1)
        // Implement your search logic here
        if (ra && dec) {
            setName('')
        }
        else if (name) {
            setRA('')
            setDEC('')
        }
        setImgSrc(null);

        get_dual_data(ra, dec, name);
        get_single_data(ra, dec, name);
        get_psf_data(ra, dec, name);
        // requestIm(ra, dec);
    }

    
    const get_dual_data = async (ra = null, dec = null, name = null) => {
        const data = make_body_request(ra, dec, name)
        if (!data) {return null}
    
        axios.post(`/api/get_object_dual_data`, data, {timeout: 8000})
            .then(res => {
                setDualData(res.data)
            })
            .catch(err => {
                console.log(err)
                setDualData(0)
            });
    }

    const get_single_data = async (ra = null, dec = null, name = null) => {
        const data = make_body_request(ra, dec, name)
        if (!data) {return null}
    
        axios.post(`/api/get_object_single_data`, data, {timeout: 8000})
            .then(res => {
                setSingleData(res.data)
            })
            .catch(err => {
                console.log(err)
                setSingleData(0)
            });
    }

    const get_psf_data = async (ra = null, dec = null, name = null) => {
        const data = make_body_request(ra, dec, name)
        if (!data) {return null}
    
        axios.post(`/api/get_object_psf_data`, data, {timeout: 8000})
            .then(res => {
                setPsfData(res.data)
            })
            .catch(err => {
                console.log(err)
                setPsfData(0)
            });
    }

    useEffect(() => {
        if (dualData === 0 && singleData === 0 && psfData == 0) {
            setLoading(false)
            setPsfData(null)
            setSingleData(null)
            setDualData(null)
            toast.error("There was an error fetching the data.");
        }
        // Define the wavelengths for each band
        const SPLUS_WAVELENGTHS = {
            "i": 7670.59,
            "r": 6251.83,
            "g": 4758.49,
            "z": 8936.64,
            "u": 3533.29,
            "J0378": 3773.13,
            "J0395": 3940.70,
            "J0410": 4095.27,
            "J0430": 4292.39,
            "J0515": 5133.15,
            "J0660": 6613.88,
            "J0861": 8607.59,
        };
    
        // Collect all band names from the SPLUS_WAVELENGTHS keys
        const bandNames = Object.keys(SPLUS_WAVELENGTHS);
    
        // Sort the band names by their corresponding wavelengths
        const sortedBandNames = bandNames.sort(
            (a, b) => SPLUS_WAVELENGTHS[a] - SPLUS_WAVELENGTHS[b]
        );
    
        const datasets = [];
    
        // Helper function to get the appropriate data value
        const getDataValue = (data, band, suffix) => {
            const value = data && data[`${band}${suffix}`] !== undefined ? data[`${band}${suffix}`] : null;
            // Check if the value is 99 or -99 and return null if so
            return (value === 99 || value === -99) ? null : value;
        };
    
        if (dualData || singleData || psfData) {
            // Map the sorted band names to their respective datasets
            if (dualData) {
                if (name) {
                    setNAMERA(dualData['RA'])
                    setNAMEDEC(dualData['DEC'])
                    setOption(dualData['Field'])
                }
                datasets.push({
                    label: 'Dual',
                    data: sortedBandNames.map(band => getDataValue(dualData, band, '_auto')),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    fill: false, // Optional: if you want lines without a filled area underneath
                });
            }
            if (singleData) {
                if (name && !dualData) {
                    setNAMERA(singleData['ID_RA'])
                    setNAMEDEC(singleData['ID_DEC'])
                }
                datasets.push({
                    label: 'Single',
                    data: sortedBandNames.map(band => getDataValue(singleData, band, '_auto')),
                    borderColor: 'rgb(54, 162, 235)',
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                    fill: false, // Optional
                });
            }
            if (psfData) {
                if (name && !dualData && !singleData) {
                    setNAMERA(psfData['ID_RA'])
                    setNAMEDEC(psfData['ID_DEC'])
                }
                datasets.push({
                    label: 'PSF',
                    data: sortedBandNames.map(band => getDataValue(psfData, band, '_psf')),
                    borderColor: 'rgb(75, 192, 192)',
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    fill: false, // Optional
                });
            }
    
            // Update chart data
            setChartData({
                labels: sortedBandNames, // Use the sorted band names as labels
                datasets: datasets,
            });

            console.log(fetchingIm, ra, dec, imgSrc, nameRA, nameDEC)
            if (!fetchingIm && ra && dec && !imgSrc) {
                requestIm(ra, dec);
            }
            else if (!fetchingIm && nameRA && nameDEC && !imgSrc){
                requestIm(nameRA, nameDEC);
            }
            
        }
    }, [dualData, singleData, psfData]);

	const requestIm = (ra, dec) => {
        setFetchingIm(true)
		const data = {
			ra: (ra ? ra : nameRA),
			dec: (dec ? dec : nameDEC),
			size: size,
			R: R,
			G: G,
			B: B,
			stretch: stretch,
			Q: 5,
			option: 1,
			dr: null
		}

        const headers = {
            ...getToken(),
            responseType: 'arraybuffer'
        }

		axios.post(`/api/lupton_image`, data, headers)
			.then(response => {
				let blob = new Blob(
					[response.data], 
					{ type: response.headers['content-type'] }
				)
				var image = URL.createObjectURL(blob)
				setImgSrc(image)
                setFetchingIm(false)
                setLoading(false)
			})
			.catch(error => {
				toast.error("There was an error fetching the image.", error);
                setFetchingIm(false)
                setLoading(false)
			});	
	}

   

    const downloadFitsStamp = (filter) =>{
        setReq(true)
		const data = {
			ra: (ra ? ra : nameRA),
			dec: (dec ? dec : nameDEC),
			size: size,
			band: fitsBand,
			weight: false,
			option: option,
			dr: null
		}
        
		axios.post(`/api/download_stamp`, data, {...getToken(), responseType: 'arraybuffer'})
            
			.then(response => {
				let blob = new Blob(
					[response.data], 
					{ type: response.headers['content-type'] }
				)
				
				var url = window.URL.createObjectURL(blob);
		        var a = document.createElement('a');
		        a.href = url;
		        a.download = data['ra'] + "_" + data['dec'] + "_" + data['size'] + "_" + data['band'] + "_" + (data['weight'] ? 'swpweight' : 'swp') + ".fits";
		        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
		        a.click();    
		        a.remove();  //afterwards we remove the element again         
				
				setReq(false)
			})
			.catch(error => {
                if (error.response.status === 401){
                    toast.warn("You need to be logged in to download the fits stamp.", error);
                }
				const decoded = JSON.parse(new TextDecoder("utf-8").decode(error.response.data));
				toast.error(decoded.error, error);
				setReq(false)
			})
			.catch(error => {
				toast.error("There was an error fetching the image.", error);
				setReq(false)
			});

    }

    // Define the options for your line chart
    const lineChartOptions = {
        maintainAspectRatio: false, // Add this to prevent chart.js from maintaining the aspect ratio
        // ... your other options
        scales: {
            y: { // 'yAxes' is replaced with 'y' in Chart.js 3
              reverse: true, // This will reverse the y-axis
              // ... any other y-axis options
            }
        }
    };

    return (
        <div className='bg-gray-100'>
            <div className="flex flex-col items-center justify-center min-h-screen px-4 md:px-10 max-w-6xl m-auto">
                <h1 className="text-2xl font-bold text-gray-700">Astronomical Object Explorer</h1>
                <p className="text-gray-600 mb-6">This component allows users to input either coordinates or the name of an astronomical object. It fetches and displays data and images from the S-PLUS database.</p>
  
                <div className="flex flex-wrap w-full">

                
                {/* Left Column for Search Inputs */}
                <div className="my-2 px-2 w-full overflow-hidden lg:w-1/3 xl:w-1/4">
                    <div className="flex flex-col justify-between h-full p-6 bg-white shadow rounded-lg">
                        <div className="flex flex-col space-y-4">
                            <input
                                type="text"
                                placeholder="Right Ascension (RA)"
                                className="p-3 border text-sm border-gray-300 rounded-md"
                                value={ra}
                                onChange={(e) => setRA(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder="Declination (DEC)"
                                className="p-3 border text-sm border-gray-300 rounded-md"
                                value={dec}
                                onChange={(e) => setDEC(e.target.value)}
                            />
                            <div className='p-2'>
                                <p className='font-bold'>OR</p>
                            </div>
                            <input
                                type="text"
                                placeholder="Name"
                                className="p-3 border text-sm border-gray-300 rounded-md"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            
                            <div className='h-[30px]'></div>
                                {!loading ?
                                <button
                                    className="flex items-center justify-center p-3 text-white bg-myblue rounded-md hover:bg-blue-700 transition duration-300"
                                    onClick={handleSearch}
                                >
                                    Search
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                    </svg>
                                </button>
                                :
                                <div className='h-[100px] relative'>
                                    <div className="spin">
                                    <img src={process.env.PUBLIC_URL + '/images/Icone_Colorido1_TextoAzulEscuro.png'} alt="Loading" />
                                    </div>
                                </div>
                                }
                            </div>
                    </div>
                </div>

                {/* Right Column for Image and Stats */}
                <div className="my-2 px-2 w-full overflow-hidden lg:w-2/3 xl:w-3/4">
                    <div className="flex flex-col md:flex-row h-full">
                    <div className="md:w-1/2 p-6 bg-white shadow rounded-lg relative">
                        <img src={imgSrc} className="max-w-full h-auto max-h-[300px] rounded-lg shadow-md" alt="" />
                        {imgSrc && (
                            <div className="mt-2 bottom-4 left-6 flex items-center">
                                <a
                                    href={imgSrc}
                                    download={`${ra}_${dec}_${size}.png`}
                                    className="inline-block bg-indigo-600 text-white px-6 py-2 rounded shadow hover:bg-indigo-700 transition duration-300"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                    </svg>
                                </a>
                            
                                <button 
                                    className='mx-2 ml-6 flex bg-blue-200 text-black px-6 py-2 rounded shadow transition duration-300' 
                                    onClick={() => downloadFitsStamp(fitsBand)}
                                    disabled={req}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
                                    </svg>
                                    .fits
                                </button>
                        
                                <select 
                                    className='inline-block bg-blue-200 px-6 pl-3 w-[95px] rounded border-l-0 shadow transition duration-300' 
                                    onChange={(e) => setFitsBand(e.target.value)}
                                >
                                    <option value="R">R</option>
                                        <option value="G">G</option>
                                        <option value="I">I</option>
                                        <option value="U">U</option>
                                        <option value="Z">Z</option>

                                        <option value="J0378">J0378</option>
                                        <option value="J0395">J0395</option>
                                        <option value="J0410">J0410</option>
                                        <option value="J0430">J0430</option>
                                        <option value="J0515">J0515</option>
                                        <option value="j0660">J0660</option>
                                        <option value="J0861">J0861</option>
                                </select>
                        </div>
                        
                        )}
                    </div>
                    {imgSrc && (
                        <div className='md:w-1/2 p-6 bg-gray-50 shadow rounded-lg relative'>
                            <div className='mb-4'>
                                <p>Parameters from lupton image.</p>
                            </div>
                            <div className="space-y-4">
                            <div className="flex items-center">
                                <label className='text-sm pr-2 flex-none'>Size</label>
                                <input 
                                className='flex-grow p-1 text-sm border border-gray-300 rounded-md'
                                type='text'
                                placeholder='size'
                                value={size}
                                onChange={(e) => setSize(e.target.value)}
                                />
                            </div>
                            <div className="flex items-center">
                                <label className='text-sm pr-2 flex-none'>Stretch</label>
                                <input 
                                className='flex-grow p-1 text-sm border border-gray-300 rounded-md'
                                type='text'
                                placeholder='stretch'
                                value={stretch}
                                onChange={(e) => setStretch(e.target.value)}
                                />
                            </div>
                            <div className="flex items-center">
                                <label className='text-sm pr-2 flex-none'>R</label>
                                <input 
                                className='flex-grow p-1 text-sm border border-gray-300 rounded-md'
                                type='text'
                                placeholder='R'
                                value={R}
                                onChange={(e) => setR(e.target.value)}
                                />
                            </div>
                            <div className="flex items-center">
                                <label className='text-sm pr-2 flex-none'>G</label>
                                <input 
                                className='flex-grow p-1 text-sm border border-gray-300 rounded-md'
                                type='text'
                                placeholder='G'
                                value={G}
                                onChange={(e) => setG(e.target.value)}
                                />
                            </div>
                            <div className="flex items-center">
                                <label className='text-sm pr-2 flex-none'>B</label>
                                <input 
                                className='flex-grow p-1 text-sm border border-gray-300 rounded-md'
                                type='text'
                                placeholder='B'
                                value={B}
                                onChange={(e) => setB(e.target.value)}
                                />
                            </div>
                        
                            <div className="bottom-4 w-full left-6">
                                <button 
                                className='p-1 text-white w-full bg-myblue rounded-md hover:bg-blue-700 transition duration-300'
                                onClick={() => requestIm(ra, dec)}
                                >
                                Re-Generate Image
                                </button>
                            </div>
                            </div>
                        </div>
                        
                        )}
                    </div>
                </div>
            </div>

            <div className="w-full my-4 px-2 flex flex-wrap">
            {/* Chart Column */}
            <div className="w-full lg:w-3/5 p-6 bg-white shadow rounded-lg">
                {chartData && Object.keys(chartData).length > 0 && (
                    <div style={{ height: '380px' }}> {/* Set the desired height here */}
                        <Line data={chartData} options={lineChartOptions} />
                    </div>
                )}
            </div>

            {/* Data Column */}
            <div className="w-full lg:w-2/5 p-6 bg-gray-50 shadow rounded-lg">
                    {/* Data Display */}
                    {dualData && (
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <tbody className="divide-y divide-gray-200">
                                    {Object.entries(dualData).filter(([key]) => !key.endsWith('_auto')).map(([key, value]) => (
                                        <tr key={key}>
                                            <td className="px-4 py-2 whitespace-nowrap text-[12px] font-medium text-gray-900">
                                                {key.replace('_', ' ').toUpperCase()}
                                            </td>
                                            <td className="px-4 py-2 whitespace-nowrap text-[12px] text-gray-500">
                                                {value}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>

        </div>
    </div>
);
}