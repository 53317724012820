import { useState, useEffect } from 'react'
import { getToken } from '../auth/token'
import { toast } from 'react-toastify'
import axios from 'axios'

import Spin from '../common/Spinning'
import { Link } from 'react-router-dom'



export default function AdminNews(){

	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')

	const post = () => {
		const data = {title, description}
		axios.post('/api/admin/submitNews', data, getToken())
			.then(res => {
				toast(res.data.msg)
			})
			.catch(err => {
				console.log(err)
			})
	}

	return (
		<div className="p-20 m-auto max-w-2xl">
			<p className="font-bold text-center text-3xl mt-16">Post News</p>
			<div>
				<input type="text" className="border rounded w-full mt-4" placeholder="title" onChange={(e) => setTitle(e.target.value)}/>
			</div>
			<div>
				<textarea type="text" placeholder="description" className="my-4 h-100px border rounded w-full" onChange={(e) => setDescription(e.target.value)}/>
			</div>

			<button className="border round px-4 py-2 rounded bg-gray-200" onClick={() => post()}>
				Post
			</button>
		</div>	
		)

}