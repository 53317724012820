import { useState, useEffect } from 'react'
import axios from 'axios'
import { getToken } from './../../auth/token'
import { toast } from 'react-toastify'

export default function ThreeFilter(){

	const [RA, setRA] = useState('')
	const [DEC, setDEC] = useState('')
	const [R, setR] = useState('I')
	const [G, setG] = useState('R')
	const [B, setB] = useState('G')

	const [stretch, setStretch] = useState('3')
	const [Q, setQ] = useState('8')

	const [pixels, setPixels] = useState('')
	const [req, setReq] = useState(false)

	const [image, setImage] = useState(null)
	const [imgSrc, setImgSrc] = useState()

	const [option, setOption] = useState(1)

	const requestIm = () => {
		setReq(true)
		const data = {
			ra: RA,
			dec: DEC,
			size: pixels,
			R: R,
			G: G,
			B: B,
			stretch: stretch,
			Q: Q,
			option: option,
			dr: null
		}

		axios.post(`/api/lupton_image`, data, {
				...getToken(),
				responseType: 'arraybuffer'
			})
			.then(response => {
				let blob = new Blob(
					[response.data], 
					{ type: response.headers['content-type'] }
				)
				var image = URL.createObjectURL(blob)
				setImgSrc(image)
				setReq(false)
			})
			.catch(error => {
				const decoded = JSON.parse(new TextDecoder("utf-8").decode(error.response.data));
				toast.error(decoded.error, error);
				setReq(false)
			})
			.catch(error => {
				toast.error("There was an error fetching the image.", error);
				setReq(false)
			});	
	}


	return(
		<div>
			<div className="text-left mb-8">
				<h3 className="text-2xl font-bold">RGB Composite Image (3 Bands)</h3>
				<p>
					This RGB composite image utilizes an algorithm detailed by <a className="text-sky-700" href="https://ui.adsabs.harvard.edu/abs/2004PASP..116..133L">Lupton et al. (2004)</a> for creating red-green-blue composites from three separate high-dynamic range arrays.
				</p>
				<br></br>
				<p>Use the "Stretch" field to define the linear stretch of the image, and the "Q" field for the asinh softening parameter.</p>
				<p>For overlapping coordinates across multiple fields, use the "option" field. Option value "2" selects the second closest field. Alternatively, specify the field name directly.</p>
			</div>
			<div className="py-12 px-4 md:grid md:grid-cols-3 md:gap-6 max-w-7xl m-auto">
		   		
		   	<div>
			   <div className="space-y-4">
					{/* RA Field */}
					<div className="flex items-center rounded-md shadow-sm">
						<span className="inline-flex items-center px-3 py-2 bg-gray-100 text-gray-600 border border-r-0 border-gray-300 rounded-l-md">
							RA
						</span>
						<input
							onChange={(e) => setRA(e.target.value)}
							type="text"
							className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>

					{/* DEC Field */}
					<div className="flex items-center rounded-md shadow-sm">
						<span className="inline-flex items-center px-3 py-2 bg-gray-100 text-gray-600 border border-r-0 border-gray-300 rounded-l-md">
							DEC
						</span>
						<input
							onChange={(e) => setDEC(e.target.value)}
							type="text"
							className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>

					{/* Pixels Field */}
					<div className="flex items-center rounded-md shadow-sm">
						<span className="inline-flex items-center px-3 py-2 bg-gray-100 text-gray-600 border border-r-0 border-gray-300 rounded-l-md">
							Pixels
						</span>
						<input
							onChange={(e) => setPixels(e.target.value)}
							type="text"
							className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>

					{/* RGB Fields */}
					<div className="grid xl:grid-cols-3 gap-4">
						{[['R', R, setR], ['G', G, setG], ['B', B, setB]].map(([label, value, setter]) => (
							<div className="flex items-center rounded-md shadow-sm" key={label}>
								<span className="inline-flex items-center px-3 py-2 bg-gray-100 text-gray-600 border border-r-0 border-gray-300 rounded-l-md">
									{label}
								</span>
								<input
									onChange={(e) => setter(e.target.value)}
									type="text"
									value={value}
									className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
						))}
					</div>

					{/* Stretch & Q Fields */}
					<div className="grid xl:grid-cols-2 grid-cols-1 gap-4">
						{[['Stretch', stretch, setStretch], ['Q', Q, setQ]].map(([label, value, setter]) => (
							<div className="flex items-center rounded-md shadow-sm" key={label}>
								<span className="inline-flex items-center px-3 py-2 bg-gray-100 text-gray-600 border border-r-0 border-gray-300 rounded-l-md">
									{label}
								</span>
								<input
									onChange={(e) => setter(e.target.value)}
									type="text"
									value={value}
									className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
						))}
					</div>

					{/* Option Field */}
					<div className="flex items-center rounded-md shadow-sm">
						<span className="inline-flex items-center px-3 py-2 bg-gray-100 text-gray-600 border border-r-0 border-gray-300 rounded-l-md">
							Option
						</span>
						<input
							onChange={(e) => setOption(e.target.value)}
							type="text"
							value={option}
							className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
				</div>


					<div className="flex rounded-md mt-4">
						{!req ?
								<button
									onClick={() => requestIm()}
									type="button"
									disabled={req}
									className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
								>
									Request
								</button>
								:
								<div className='h-[100px] relative m-auto'>
									<div className="spin">
										<img src={process.env.PUBLIC_URL + '/images/Icone_Colorido1_TextoAzulEscuro.png'} alt="Loading" />
									</div>
								</div>
							}
					</div>
					</div>

					<div className="col-span-2 items-center justify-center">
						<img src={imgSrc} className="m-auto md:ml-0 pt-6 md:pt-0" alt="" />
						{imgSrc && (
							<a
								href={imgSrc}
								download={`${RA}_${DEC}_${pixels}.png`}
								className="mt-2 inline-block bg-indigo-600 text-white px-6 py-2 rounded shadow hover:bg-indigo-700 transition duration-300"
							>
								Download Image
							</a>
						)}
					</div>
				</div>
			</div>
		)
}