import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom'

import axios from 'axios'
import { toast } from 'react-toastify'

import { setCookie } from './cookies'
import { getToken, getUser, getIsAuth, getCollab } from './token'

const AuthContext = React.createContext();

const AuthProvider = (props) => {

	const [isAuth, setIsAuth] = useState(getIsAuth())
	const [user, setUser] = useState(getUser())
	const [isCollab, setCollab] = useState(getCollab())


	const navigate = useNavigate()

	// useEffect(() => {
	// 	testUser()
	// }, [])


	function login(username, password) {
	// setting timeout to mimic an async login
		if (username && password) {

		  axios.post(`/api/auth/login`, ({ username, password }))
				.then(res => {
				  localStorage.setItem('user', JSON.stringify(res.data));
				  localStorage.setItem('isAuth', true);
				  setCookie("user", JSON.stringify(res.data), 1)
				  setUser(res.data)
				  toast.success('Logged in with success!')
				  navigate("/")
				  checkcollab()
				  document.location.reload()
				})
				.catch(err => {
				  console.log(err.message)
				  toast.error('Invalid credentials')
				})
			}
	}

	function checkcollab() {

		  axios.post(`/api/auth/collab`, null, getToken())
				.then(res => {
				  localStorage.setItem('isCollab', JSON.stringify(res.data));
				  
				})
				.catch(err => {
				  
				})
	}

	function register(email, username, password){
		axios.post(`/api/auth/register`, ({email, username, password}))
			.then(res => {
				toast.success('Registered with success!')
				navigate('/')
				login(username, password)
			})
			.catch(err => {
				toast.error(`Error while registering: ${err.message}`)
				toast.error(`Username already in use!`)
			})
	}

	function logout() {
		localStorage.removeItem('user')
		localStorage.removeItem('isAuth')
		localStorage.removeItem('isCollab')
		axios.post(`/api/auth/logout`, null, getToken())
			.then(res => {
				
			})
			.catch(err => {});
		setUser({})
		setIsAuth(false)
	}

	function testUser() {
	  	
	  	if (getToken() === {}){
	  		setIsAuth({isAuth: false})
	  		return
	  	}

	  	checkcollab()
	  	axios.get(`/api/auth/user`, getToken()) 
	              .then(res =>{
	                // this.props.logout()
	                setIsAuth(true)
	                setUser(getUser)
	                
	              }).catch(err =>{
	            		localStorage.removeItem('user');
	            		localStorage.removeItem('isAuth');
	            		localStorage.removeItem('isCollab');
	            		setUser({})
	            		setIsAuth(false)
	              })
	}

	function passreset(email) {
		axios.post(`/api/reset-password/`, ({email}))
			.then(res => {
				toast.success('Email with token sent!')
				navigate('/changepass')
			})
			.catch(err => {
				toast.error('Error requesting token to change password')
			})
	}

	function passchange(token, password) {
		axios.post(`/api/reset-password/confirm/`, ({token, password}))
			.then(res => {
				toast.success('Password change with success!')
				navigate('/login')
			})
			.catch(err => {
				toast.error('Error changing password, check if token is valid!')
			})
	}

	const authData = useMemo(() => ({
		isAuth, 
		user,
		login,
		logout,
		register,
		testUser,
	  	passreset,
	  	passchange
	}), [isAuth, user])

	return (
	  <AuthContext.Provider
		value={authData}
	  >
		{props.children}
	  </AuthContext.Provider>
	)

}


const AuthConsumer = AuthContext.Consumer
export { AuthProvider, AuthConsumer, AuthContext }