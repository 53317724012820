import React from 'react'

import { UnControlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/sql/sql'

import { Link } from 'react-router-dom';

function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

class Querymaker extends React.Component {
  _isMounted = false;
  state = {
    schemas: [],
    tables: [],
    columns: [],
    conditions: []
  }

  editor = null

  componentDidMount() {
    this._isMounted = true;
  }

  addSchema = e => {
    var schema = this.state.schemas
    const field = document.getElementById("addschema")
    if (field.value === ""){
      return
    }
    
    schema.push(field.value)
    field.value = ""
    this.setState({schemas: schema})
  }

  delSchema = e => {
    var arr = this.state.schemas
    arr = removeItemOnce(arr, e.target.value)
    this.setState({schemas: arr})
  }

  addTable = e => {
    var tables = this.state.tables
    const field = document.getElementById("addtable")
    if (field.value === ""){
      return
    }
      
    const elem = document.getElementById("fromschema")
    try{
      const schema = elem.options[elem.selectedIndex].value

      tables.push(`${schema}.${field.value}`)
      field.value = ""
      this.setState({tables: tables})

      field.value = ""
    }catch{

    }
  }

  delTable = e => {
    var arr = this.state.tables
    arr = removeItemOnce(arr, e.target.value)
    this.setState({tables: arr})
  }

  addColumn = e => {
    var columns = this.state.columns
    const field = document.getElementById("addcolumn")
    if (field.value === ""){
      return
    }
    const elem = document.getElementById("fromtable")
    try{
      const table = elem.options[elem.selectedIndex].value

      columns.push(`${table}.${field.value}`)
      
      this.setState({columns: columns})
      field.value = ""
    }catch{

    }
  }

  delColumn = e => {
    var arr = this.state.columns
    arr = removeItemOnce(arr, e.target.value)
    this.setState({columns: arr})
  }

  addCondition = e => {

    var conditions = this.state.conditions
    const field = document.getElementById("conditions")
    if (field.value === ""){
      return
    }

    const elem = document.getElementById("withcolumn")
    try{
      const column = elem.options[elem.selectedIndex].value

      conditions.push(`${column} ${field.value}`)
      
      this.setState({conditions: conditions})
      field.value = ""
    }catch{

    }

  }

  delCond = e => {
    var arr = this.state.conditions
    arr = removeItemOnce(arr, e.target.value)
    this.setState({conditions: arr})
  }


  updateQuery = e => {
    var query = `SELECT TOP 50000 `

    this.state.columns.forEach((item, key) => {
      if (key === this.state.columns.length - 1){
        query += item + ' '
      }else{
        query += item + ', '
      }
    })

    query += ' FROM '

    if (this.state.tables.length > 1){
      var refTable = ""
      this.state.tables.forEach((item, key) => {
        if (refTable === ""){
          query += item + ' as ' + item.split(".")[1]
          refTable = item.split(".")[1]
        }else{
          query += item + ' as ' + item.split(".")[1]
          query += " on " + refTable + ".id = " +  item.split(".")[1] + ".id "
        }

        if (key !== this.state.tables.length - 1){
          query += " JOIN "
        }
      })
    }else{
      this.state.tables.forEach(item => {
        query += item + ' '
      })
    }

    if (this.state.conditions.length > 0){
      query += ' WHERE '

      this.state.conditions.forEach((item, key) => {
        if (key === this.state.conditions.length - 1){
          query += item + ' '
        }else{
          query += item + ' AND '
        }
      })

    }
      
    try{
      this.editor.getDoc().setValue(query)
    }catch{
    }
  }

  onChange = e => this.setState({[e.target.name]: e.target.value});

  render() {
    this.updateQuery()
    return (
        <div className="max-w-5xl m-auto my-6 p-4">
            <h3>Query Maker</h3>
            <p>This tool was made to help making a simple query</p>

            <ul>
              <li><small>This tool only supports "AND" conditions by now.</small></li>
              <li><small>It is set to make a query that takes the TOP 50000 objects.</small></li>
              <br/>
              <li><small>It is important that you add tables linked to the right schemas and columns linked to the right table.</small></li>
              <li><small>Start by filling in schema and table fields.</small></li>
              <br/>
              <li><small>All schema, table and column names are available at the <Link to='/query'>Catalog Tool</Link>.</small></li>
            </ul>  

            <ul>
              <li>Schema: <small>(Write a schema like DR2 in the box and press +)</small></li>
              <li>Tables: <small>(A few examples: you can use detection_image, g_band, j0378_band, r_band, only choose one of these at a time and press + as many times as you want)</small></li>
              <li>Columns: <small>(Ex: RA, DEC, FWHM_J0378, J0378_petro, CLASS_STAR_J0378, J0378_aper_3 see /query to see all the columns you have access to. Enter as many columns as you want and hit +)
                          </small>
              </li>
              <li><small>Choose conditions for each of the columns you entered.</small></li>
            </ul>

            <div className="md:grid md:grid-cols-2  mt-10">
              <div className="col input">
                  <span>Schema (<small>Ex: DR2</small>)</span>
                  <div style={{'display': 'flex'}}>
                  <input type="text" className="form-control" id="addschema" aria-label="Default" name="table" aria-describedby="inputGroup-sizing-default">
                  </input>
                  <button onClick={this.addSchema} className="inline-flex bg-gradient-to-r from-myred to-myblue text-white bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700 hover:text-white">+</button>
                  </div>
                  
                  <br/>
                  {this.state.schemas.map(item => (
                    <div key={item} style={{display: 'flex'}}>
                      <p>{item}</p>
                      <button onClick={this.delSchema} value={item} style={{height: '22px', width: '22px', padding: '0'}} className="btn btn-danger">x</button>
                    </div>
                  ))}
                </div>

                <div className="col input">
                  <span>Tables (<small>Ex: detection_image</small>)</span>
                  <div style={{'display': 'flex'}}>
                  <input type="text" className="form-control" id="addtable" aria-label="Default" name="table" aria-describedby="inputGroup-sizing-default">
                  </input>
                  <button onClick={this.addTable} className="inline-flex bg-gradient-to-r from-myred to-myblue text-white bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700 hover:text-white">+</button>
                  </div>
                  <small>From schema: </small>
                  <select id="fromschema" type="band" required name="band" className="dropdown" onChange={this.onChange}>
                    {this.state.schemas.map(item => (
                        <option key={item} value={item}>{item}</option>
                      ))}
                  </select>

                  <br/>
                  {this.state.tables.map(item => (
                    <div key={item} style={{display: 'flex'}}>
                      <p>{item}</p>
                      <button onClick={this.delTable} value={item} style={{height: '22px', width: '22px', padding: '0'}} className="btn btn-danger">x</button>
                    </div>
                  ))}

                </div>
              </div>

              <div className="md:grid md:grid-cols-2">
                <div className="col input">
                  <span>Columns (<small>Ex: ra</small>)</span>
                  <div style={{'display': 'flex'}}>
                  <input type="text" className="form-control" aria-label="Default" id ="addcolumn" name="columns" aria-describedby="inputGroup-sizing-default">
                  </input>
                  
                  <button onClick={this.addColumn} className="inline-flex bg-gradient-to-r from-myred to-myblue text-white bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700 hover:text-white">+</button>
                  </div>

                  <small>From table: </small>
                  <select id="fromtable" type="band" required name="band" className="dropdown" onChange={this.onChange}>
                    {this.state.tables.map(item => (
                        <option key={item} value={item.split('.')[1]}>{item.split('.')[1]}</option>
                    ))}
                  </select>

                  <br/>
                  {this.state.columns.map(item => (
                    <div key={item} style={{display: 'flex'}}>
                      <p>{item}</p>
                      <button onClick={this.delColumn} value={item} style={{height: '22px', width: '22px', padding: '0'}} className="btn btn-danger">x</button>
                    </div>
                  ))}

                </div>

                <div className="col input">
                  <span>Conditions (<small>Ex: > 17</small>)</span>
                  <div style={{'display': 'flex'}}>

                  <select id="withcolumn" type="band" required name="band" className="dropdown" onChange={this.onChange}>
                    {this.state.columns.map(item => (
                        <option key={item} value={item}>{item.split('.')[1]}</option>
                    ))}
                  </select>

                  <input type="text" className="form-control" aria-label="Default" id="conditions" name="conditions" aria-describedby="inputGroup-sizing-default">
                  </input>
                  <button onClick={this.addCondition} className="inline-flex bg-gradient-to-r from-myred to-myblue text-white bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700 hover:text-white">+</button>
                  </div>

                  <br/>
                  {this.state.conditions.map(item => (
                    <div key={item} style={{display: 'flex'}}>
                      <p>{item}</p>
                      <button onClick={this.delCond} value={item} style={{height: '22px', width: '22px', padding: '0'}} className="btn btn-danger">x</button>
                    </div>
                  ))}

                </div>
              </div>

              <CodeMirror
              options={{ mode: 'sql', lineNumbers: true, lineWrapping: true, readOnly: true }}
              editorDidMount={editor => this.editor = editor}
              onChange={(editor, data, value) =>{ 
                this.setState({ query: value })}
              } />

              <small>You may copy this query and test it in the Catalog Tools!</small>

          </div>
    )
  }
}


export default Querymaker;