
import React, {useEffect, useState} from "react";
import axios from "axios";
import { SearchIcon } from '@heroicons/react/outline';

import { useMatch } from 'react-router-dom';
import { getToken } from '../auth/token'
import DocumentationPage from "./DocumentationPage";

export default function CommunityCodes() {

    const [tools, setTools] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const params = useMatch('/community-codes/:path')

    useEffect(() => {
        getCodes()
    }, []);

    const getCodes = () => {
        axios.get('/api/get_community_codes')
        .then(res => {
            if (params){
                const tool = res.data.filter(tool => tool.title.replace(/\s/g, "_") === params.params.path)
                setTools(tool)
            }else{
                setTools(res.data);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const handleSearchClick = () => {
        if (inputValue.trim() === ''){
            getCodes()
        }else{
            const config = getToken()
            axios.post('/api/search_community_codes', {contains: inputValue}, config)
                .then(res=> {
                    if (res.data === 'Empty' || Object.keys(res.data).length === 0 ){
                        setTools([])
                    }else{
                        setTools(res.data)
                    }
                    
                })
                .catch(error => {
                    console.log(error);
                });
            }
    }

    const onEnter = e => {
        if (e.key === 'Enter'){
            const config = getToken()
          if (e.target.value.trim() === ''){
            getCodes()
          }else{
            axios.post('/api/search_community_codes', {contains: e.target.value}, config)
            .then(res=> {
                if (res.data === 'Empty' || Object.keys(res.data).length === 0 ){
                    setTools([])
                }else{
                    setTools(res.data)
                }
              
            })
            .catch(error => {
                console.log(error);
            });
          }
        }
        
      }

    return (
        <div className="min-h-screen bg-gray-100"> 
            <div className="text-center py-10">
                <h1 className="text-3xl font-bold mb-4">Welcome to S-PLUS Community Codes</h1>
                <p className="max-w-2xl mx-auto mb-10">
                    Explore a collection of codes shared by the S-PLUS community. Contribute your own insights, or discover new ways to analyze and visualize data from the Southern Photometric Local Universe Survey. Whether you're an experienced astronomer or just starting out, this is the perfect place to collaborate and grow.
                </p>
            </div>
            <div className="flex justify-center items-center py-10">
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => onEnter(e)}
                    className="p-2 border rounded"
                    placeholder="Search"
                />
                <button
                    onClick={handleSearchClick}
                    className="flex items-center justify-center px-4 border-l"
                >
                <SearchIcon className="h-5 w-5 text-gray-700" />
                </button>
            </div>
            <div className="grid justify-center">
                {tools.map((tool, index) => (
                    <ToolCard key={index} tool={tool} />
                ))}
            </div>
        </div>
        
    );
}


const ToolCard = ({ tool }) => {
    const [isExpanded, setIsExpanded] = useState(false);
  
    const toggleDescription = () => {
      setIsExpanded(!isExpanded);
    };
  
    return (
      <div className="flex flex-row overflow-hidden shadow-lg bg-white rounded-lg m-4" style={{ maxWidth: '800px', transition: 'all 0.5s ease' }}>
        <div className="flex-grow p-4">
        
          <div className="flex justify-between items-start">
            <div>
              <div  className="font-bold text-2xl mb-2 cursor-pointer" onClick={toggleDescription}>{tool.title}</div>
              <p className="text-gray-700">Author: {tool.author}</p>
              <p className="text-gray-700">Language: {tool.primary_language}</p>
              
            </div>
            <div className={`justify-start p-7`}>
                <a href={tool.repository} className="inline-block bg-blue-500 rounded-full px-3 py-1 text-sm font-semibold text-white mb-2">GitHub</a>
            </div>
            <button
              onClick={toggleDescription}
              className="text-blue-500 text-xl p-7"
              aria-label={isExpanded ? 'Collapse' : 'Expand'}
            >
              {isExpanded ? '↑' : '↓'}
            </button>
          </div>
          <div className={`transition-all duration-100 ease-in-out overflow-hidden ${isExpanded ? '' : 'max-h-0'}`}>
            <div className="text-gray-700 text-base mt-4">
              <DocumentationPage 
                content={tool.description} 
                sections={false} 
                sidemenu={false}
              />
            </div>
          </div>
        </div>
        
      </div>
    );
  };
  
  