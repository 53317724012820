import { Link } from 'react-router-dom';

const supportLinks = [
  {
    name: 'Image Tools',
    href: '/imagetools/1filter',
    description:
      'Coordinate-based object search. Craft vibrant images with diverse filter combinations.',
  },
  {
    name: 'Catalog Tools',
    href: '/catalogtools/tap',
    description:
      'Harness our TAP system for object queries. Identify fields associated with specific coordinates.',
  },
  {
    name: 'Python Package',
    href: '/documentation/python',
    description:
      "Explore our Python package, splusdata. With integrated tools for catalog and image downloads, it's ideal for seamless code integration.",
  },
];

export default function Tools() {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Header */}
      <div className="relative pb-32 bg-blue-support">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover opacity-50"
            src={process.env.PUBLIC_URL + '/images/Onda_SemCor_Branco.png'}
            alt="Background Pattern"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8 text-center">
          <img className="h-36 w-36 mx-auto mb-4" src={process.env.PUBLIC_URL + '/images/Icone_Colorido2_TextoBranco.png'} alt="S-PLUS Logo" />
          <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">S-PLUS Tools</h1>
          <p className="mt-6 max-w-3xl mx-auto text-xl text-white">
            Dive deep into the realm of S-PLUS Data. Access official data releases via browser, TopCat, or Python.
          </p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl h-56 relative">
              <div className="flex-1 p-6">
                <h3 className="text-xl font-medium text-gray-900">{link.name}</h3>
                <p className="mt-4 text-base text-gray-500">{link.description}</p>
              </div>
              <div className="absolute bottom-6 left-6 right-6">
                <Link to={link.href} className="text-base font-medium text-myblue hover:text-indigo-600">
                  Access <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
