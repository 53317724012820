import { Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom'
import { AuthConsumer, AuthContext } from './AuthContext';

import { useContext } from 'react'

export default function PrivateRoute({ children }) {
  const con = useContext(AuthContext)
  return con.isAuth ? children : <Navigate to="/login" />;
}
