export function getToken() {
	let user = JSON.parse(localStorage.getItem('user'));

	const config = {
	headers:{
	  'Content-Type': 'application/json'
		}
	};

	if (user && user.token) {
	    config.headers['Authorization'] = `Token ${user.token}`;
	    return config
	} else {
	    return {};
	}
}

export function getUser() {
	let user = JSON.parse(localStorage.getItem('user'));

	if (user) {
	    return user
	} else {
	    return {};
	}
}

export function getIsAuth() {
	var isAuth = JSON.parse(localStorage.getItem('isAuth'));


	if (isAuth) {
	    return isAuth
	} else {
	    return false;
	}
}

export function getCollab() {
	var isCollab = JSON.parse(localStorage.getItem('isCollab'));


	if (isCollab) {
	    return isCollab
	} else {
	    return false;
	}
}


module.export = ({getToken, getUser, getIsAuth, getCollab})