import { useState, useEffect } from 'react'
import { getToken } from '../auth/token'
import { toast } from 'react-toastify'
import axios from 'axios'

import Spin from '../common/Spinning'
import { Link } from 'react-router-dom'

import { PencilIcon, SaveIcon } from '@heroicons/react/outline';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function Info(){

	const [myinfo, setInfo] = useState({})
	const [missingInfo, setMissingInfo] = useState(false)
	const [editing, setEditing] = useState(false)

	const [loading, setLoading] = useState(false)

	const getInfo = () => {
        var config = getToken()
        setLoading(true)
        axios.get('/api/getProfile', {headers: { 'Authorization': config.headers['Authorization'] }})
        .then(res => {
        	setLoading(false)
        	delete res.data['public']
            setInfo(res.data)
        }).catch(err => {
        	setLoading(false)
			
        	toast.error('Error loading profile info.')
        })
    }

    useEffect(() => {
    	getInfo()
    }, [])
    useEffect(() => {
    	setMissingInfo(false)
    	Object.keys(myinfo).map(key => {
    		if (!myinfo[key]){
    			setMissingInfo(true)
    		}
    	})
   	}, [loading])

   	const handleChange = (key, value) => {
   		const obj = JSON.parse(JSON.stringify(myinfo));
   		obj[key] = value
   		setInfo(obj)
   	}


   	const updateInfo = () => {

   		const config = getToken()
   		const data =  {}

   		Object.keys(myinfo).map((item, cont) => {
            if (item !== 'id' && item !== 'email' && item !== 'calib_frames' && item !== 'specific_fields_access'){
                data[item] = myinfo[item]
            }            
        })

   		axios.post('/api/updateProfile', data, {headers: { 'Authorization': config.headers['Authorization'] }})
        .then(res=>{
        	toast.success('Updated profile!')
        	setEditing(false)

        	getInfo()
        })
        .catch(err => {
        	toast.error('Error updating profile.')
        })
   	}


	   return (
		<div className="sm:p-4 md:px-6 lg:px-8 w-full m-auto">
		  <div className="space-y-4">
			{myinfo.member >= 5 && (
			  <Link to="/admin" className="btn bg-black text-white rounded-md px-4 py-2 flex items-center space-x-2 hover:bg-gray-800 transition duration-150">
				<span>Admin</span>
			  </Link>
			)}
	
			{Object.keys(myinfo).length ? (
			  	<div className="bg-white shadow-lg rounded-lg p-6 space-y-4 transition-transform duration-150 transform hover:scale-101">
				{myinfo.member >= 3 && (
					<div>
					
					<p className='text-gray-600'>
						Check for internal policies and splus projects.
					</p>
					<Link to="/members" className="btn bg-indigo-100 text-indigo-700 hover:bg-indigo-200 rounded-md px-4 py-2 flex items-center space-x-2 transition duration-150">
						<span>Members Section</span>
					</Link>
				  </div>
				)}
	
				<h1 className="text-2xl font-semibold mb-4 border-b pb-2">Profile Info</h1>
				
				{missingInfo && (
				  <div className="text-sm text-rose-500">
					<small>Please fill with your real information. (This does not affect your username to login)</small>
				  </div>
				)}
				
				{!editing ? (
				  <div className="space-y-4">
					<button 
					  className="btn bg-indigo-100 text-indigo-700 hover:bg-indigo-200 rounded-md px-4 py-2 flex items-center space-x-2 transition duration-150"
					  onClick={() => setEditing(true)}
					>
					  <PencilIcon className="h-5 w-5"/>
					  <span>Edit Info</span>
					</button>
					<div className="space-y-2">
					  {Object.keys(myinfo).map(key => (
						key !== 'member' && (
						  <div key={key} className="space-y-1">
							<p className="font-semibold">{capitalizeFirstLetter(key)}</p>
							<p className={myinfo[key] === null ? 'text-rose-500' : ''}>
							  {myinfo[key] ? myinfo[key].toString() : 'No information'}
							</p>
						  </div>
						)
					  ))}
					</div>
				  </div>
				) : (
				  <div className="space-y-4">
					{Object.keys(myinfo).map(key => (
					  key !== 'member' && (
						<div key={key} className="space-y-2">
						  <label className="block text-sm font-medium text-gray-700 font-bold mb-1">
							{capitalizeFirstLetter(key)}
						  </label>
						  {(key === 'email' || key === 'id' || key === 'calib_frames' || key === 'specific_fields_access') ? (
							<p className="text-gray-600">{myinfo[key]}</p>
						  ) : (
							<textarea
							  rows={4}
							  onChange={(e) => handleChange(key, e.target.value)}
							  name={key}
							  value={myinfo[key]}
							  className="w-full px-3 py-2 rounded-md shadow-sm border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 transition duration-150"
							/>
						  )}
						</div>
					  )
					))}
					<button 
					  className="btn bg-indigo-100 text-indigo-700 hover:bg-indigo-200 rounded-md px-4 py-2 flex items-center space-x-2 transition duration-150"
					  onClick={() => updateInfo()}
					>
					  <SaveIcon className="h-5 w-5"/>
					  <span>Save Info</span>
					</button>
				  </div>
				)}
			  </div>
			) : (
			  <Spin />
			)}
		  </div>
		</div>
	  );

}