import { useState, useEffect } from 'react'
import { getToken } from '../auth/token'
import { toast } from 'react-toastify'
import axios from 'axios'

import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PlusCircleIcon, UserRemoveIcon } from '@heroicons/react/outline'
import { Combobox } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Projects(){

	const [myProjects, setMyProjects] = useState([{}])
	const [editing, setEditing] = useState(false)

	const [loading, setLoading] = useState(false)

	const [people, setPeople] = useState([])

	const [query, setQuery] = useState('')
	const [selectedPerson, setSelectedPerson] = useState({})
	const [status, setStat] = useState({})
	const [paperInfo, setPaperInfo] = useState({})

	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [keywords, setKeywords] = useState('')

	const filteredPeople =
		query === ''
			? people
			: people.filter((person) => {
			  return person.name.toLowerCase().includes(query.toLowerCase())
		})


	const getProjects = () => {
        var config = getToken()
        setLoading(true)
        axios.get('/api/project/myProjects', config)
            .then(res=> {
            	if (!res.error){
	                setMyProjects(res.data)
	                console.log(res.data)
	            	setLoading(false)
            	}
            })
            .catch(err => {
            	toast.error('Error loading projects')
            	setLoading(false)
            })
    }

    useEffect(() => {
    	getProjects()
    	getMembers()
    }, [])

    const getMembers = () => {
    	const config = getToken()
        axios.get('/api/getMembers', config)
        	.then(res => {
        		setPeople(res.data)
        	})
    }

    const addCollaborator = (title, id) => {
        const config = getToken()
        
        const data = {'projectTitle': title, 'collaborator': selectedPerson[id].id}
        axios.post('/api/project/addColaborator', data, config)
        .then(res => {
            try{
                toast.success(res.data.msg)
                getProjects()
            }catch{
                toast.error(res.data.error)
            }
        })
    }

    const removeCollaborator = (title, name) => {
        const config = getToken()

        const data = {'projectTitle': title, 'collaborator': name}
        axios.post('/api/project/removeColaborator', data, config)
        .then(res => {
            try{
                toast.success(res.data.msg)
                getProjects()
            }catch{
                toast.error(res.data.error)
            }
        })
    }

    const changeStatus = (title, id) => {
        const config = getToken()

        const stat = status[id]
        if (stat === ""){
        	return
        }

        var paperInf = ''

        if(stat === 'published'){
            paperInf = paperInfo[id]
        }



        const data = {'title': title, 'status': stat, 'paperInfo': paperInf}
        axios.post('/api/project/changeStatus', data, config)
        .then(res => {
            try{
                toast.success(res.data.msg)
                getProjects()
            }catch{
                toast.error(res.data.error)
            }
        })
    }


    const setSelectedPersonHandler = (key, value) => {
   		const obj = JSON.parse(JSON.stringify(selectedPerson));
   		obj[key] = value
   		setSelectedPerson(obj)
   	}

   	const setStatusHandler = (key, value) => {
   		const obj = JSON.parse(JSON.stringify(status));
   		obj[key] = value
   		setStat(obj)
   	}

   	const setPaperInfoHandler = (key, value) => {
   		const obj = JSON.parse(JSON.stringify(paperInfo));
   		obj[key] = value
   		setPaperInfo(obj)
   	}


   	const submit = () => {
   		const data = {title: title, description: description, keywords: keywords}
        axios.post('/api/project/createProject', data, getToken())
        .then(res => {
        	if (!res.error){
        		toast.success('Project submited!')
        	}else{
        		toast.error(res.data.error)
        	}
        }).catch(err => {
        	toast.error('Error submiting project.')
        })
   	}



	return (
		<div>
			{!editing ? (

				<div className="sm:p-4 md:px-6 lg:px-8 w-full m-auto mr-0 ">
					<div className="shadow m-auto rounded h-[500px] overflow-y-scroll">
						<h1 className="p-4 bg-cover text-2xl font-bold">My Projects</h1>
						<button type="button" className="mb-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mx-4" onClick={() => setEditing(true)}>Create Project</button>
						{myProjects.map(obj => (

							<div key={obj.title} className="shadow p-4 rounded">
								

								<div className="bg-gray-300 h-[0.5px]"></div>
								<p className="p-2 font-bold text-2xl">{obj.title}</p>
								<div className="bg-gray-300 h-[0.5px]"></div>

								{!obj.approval && (
									<div className="bg-gray-300">
										<p className="p-2 font-bold text-sm">Waiting for approval.</p>
									</div>
								)}

								<div className="p-2 text-justify">
									<p>Description: </p>	
									<p className="text-sm">{obj.description}</p>
								</div>
								<div className="p-2 text-justify">
									<p>Date: </p>	
									<p className="text-sm">{obj.date}</p>
								</div>
								<div className="p-2 text-justify">
									<p>PI: </p>	
									<p className="text-sm">{obj.pi}</p>
								</div>
								<div className="p-2 text-justify">
									<p>Status: </p>	
									<p className="text-sm">{obj.status}</p>
								</div>
								{obj.paperInfo && (
									<div className="p-2 text-justify">
										<p>PaperInfo: </p>	
										<p className="text-sm">{obj.paperInfo}</p>
									</div>
									)}
								
								<div className="p-2 text-justify">
									<p>Collaborators: </p>	
										{obj.collaborators && <div>
							    			{Object.keys(obj.collaborators).map((i, key) => (

							    				obj.collaborators[i].name && (
												<div key={key} className="flex">
							    					<button onClick={() => removeCollaborator(obj.title, obj.collaborators[i].name)}><UserRemoveIcon className="h-5 w-5 text-red-500"/></button>
							    					<p className="indent-4">{obj.collaborators[i].name}</p>
							    				</div>
							    					)
							    				
							    				
							    		))}
						    		</div>}
									
								</div>
								
								<Combobox className="p-2" as="div" value={selectedPerson[obj.id]} onChange={(e) => setSelectedPersonHandler(obj.id, e)}>
								      <Combobox.Label className="block text-sm font-medium text-gray-700">Add Collaborator</Combobox.Label>
									      <div className="sm:grid sm:grid-cols-4">
									      		<div className="col-span-3">
											      <div className="relative mt-1">
											        <Combobox.Input
											          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
											          onChange={(event) => setQuery(event.target.value)}
											          displayValue={(person) => person.name}
											        />
											        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
											          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
											        </Combobox.Button>

											        {filteredPeople.length > 0 && (
											          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
											            {filteredPeople.map((person) => (
											              <Combobox.Option
											                key={person.id}
											                value={person}
											                className={({ active }) =>
											                  classNames(
											                    'relative cursor-default select-none py-2 pl-3 pr-9',
											                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
											                  )
											                }
											              >
											                {({ active, selected }) => (
											                  <>
											                    <span className={classNames('block truncate', selected && 'font-semibold')}>{person.name}</span>

											                    {selected && (
											                      <span
											                        className={classNames(
											                          'absolute inset-y-0 right-0 flex items-center pr-4',
											                          active ? 'text-white' : 'text-indigo-600'
											                        )}
											                      >
											                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
											                      </span>
											                    )}
											                  </>
											                )}
											              </Combobox.Option>
											            ))}
											          </Combobox.Options>
											        )}
											      </div>
											   </div>
									      	<div><button onClick={() => addCollaborator(obj.title, obj.id)} className="p-3 text-sky-800"><PlusCircleIcon className="h-5 w-5 text-blue-500"/></button></div>
									      </div>
									    </Combobox>
									    <p className="text-gray-700 text-sm pl-2">Change Status</p>
									    <div className="p-2 pt-1 sm:grid grid-cols-4">

									    	<div className="col-span-3">
									    		<select
											      id="tabs"
											      name="tabs"
											      onChange={(e) => setStatusHandler(obj.id, e.target.value)}
											      className="block text-sm w-full p-2 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
											    >
											    	<option value=""></option>
										        	<option value="ongoing">ongoing</option>
										        	<option value="completed">completed</option>
										        	<option value="closed">closed</option>
										        	<option value="published">published</option>
											    </select>
									    	</div>
									    	<div>
									    		<button onClick={() => {changeStatus(obj.title, obj.id)}} className="p-2 text-sky-800">Change</button>
									    	</div>
									    </div>

									    <div>
									    	{status[obj.id] === 'published' && (
									    		<div>
									    			<textarea
												          rows={4}
												          onChange={(e) => setPaperInfoHandler(obj.id, e.target.value)}
												          placeholder="Put here your paper info"
												          className="shadow-sm m-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
												          
												        />
									    		</div>
									    	)}
									    </div>


									   

							</div>
						)) }

					</div>
				</div>
			) : (
				<div className="sm:p-4 md:px-6 lg:px-8 w-full m-auto">
					<div className="shadow m-auto rounded">
						<div className="pr-2">
							<p className="p-2 pb-0 font-bold">Creating Project</p>
							<p className="p-2 pt-0  text-sm text-rose-500">Its important to save the project anywhere else just in case.</p>
							<p className="text-sm pl-2 mt-4">Title</p>
							<textarea
					          rows={2}
					          value={title}
					          onChange={(e) => setTitle(e.target.value)}
					          className="shadow-sm m-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					        />
					        <p className="text-sm pl-2">Description</p>
					        <textarea
					          rows={4}
					          value={description}
					          onChange={(e) => setDescription(e.target.value)}
					          className="shadow-sm m-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					        />
					        <p className="text-sm pl-2">Keywords</p>
					        <textarea
					          rows={2}
					          value={keywords}
					          onChange={(e) => setKeywords(e.target.value)}
					          className="shadow-sm m-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
					        />
				        </div>
				        <button className="m-2 p-2 bg-rose-300 rounded" onClick={() => setEditing(false)}>Cancel</button>
				        <button className="m-2 p-2 bg-gray-300 rounded" onClick={() => submit()}>Submit</button>
					</div>
				</div>
			)}
			

		</div>
		)

}

