


function setCookie(name,value,days) {
    var expires = "";
    var Domain = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*5*60*60*1000));
        expires = ";expires=" + date.toUTCString();
        Domain=";domain=splus.cloud"
    }
    document.cookie = name + "=" + (value || "")  + expires + ";path=/" + Domain;
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function eraseCookie(name) {   
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}


module.exports = ({ setCookie, getCookie, eraseCookie })