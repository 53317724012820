import { useState, useEffect } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark, xonokai } from 'react-syntax-highlighter/dist/esm/styles/prism';

import CopyLinkButton from '../common/CopyButton'

const createTable = (ths, trs) => {
	const theads = ths.split('**')

	const renderTD = (text, classes, index) => {
		return <td key={index} className={classes} dangerouslySetInnerHTML={{ __html: text }} />
	}

	return(
		<table className="min-w-full divide-y divide-gray-300">
			<thead className="bg-gray-50">
				<tr className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
					{theads.map((obj, index) => (
						<th key={index}>{obj}</th>
					))}
				</tr>
			</thead>
			<tbody className="divide-y divide-gray-200 bg-white">
				{trs.map((line, index) => (
					<tr key={index}>
						{line.split('**').map((item, index) => (
							(item.includes('!NLINK') ? 
								<td key={index} className="whitespace-nowrap py-4 text-sm text-gray-500"><a className="text-sky-700" key={index} href={item.replace("!NLINK", "").split("&&&&")[0]}>{item.replace("!NLINK", "").split("&&&&")[1]}</a></td>
							:
								renderTD(item, "whitespace-nowrap py-4 text-sm text-gray-500", index)
							)	
					))}
					</tr>
				))}
			</tbody>
		</table>
	)
}

function goto($hashtag){
	window.scrollTo(0, 0)
    const a = document.getElementById($hashtag)
    const rect = a.getBoundingClientRect()
	
    window.scrollTo(0, rect.top - 20)
}

function parseText(text, shareButton = false){
	const document_lines = []
	const sections = []
	const sidemenu = []

	const splitted = text.split("\n")

	var creatingTable = false
	var creatingSnippet = false
	var tableHeader = ''
	var snippet = ''
	var snippetLanguage = ''
	var tableRows = []

	const sectionElements = {
		"****": (text, index) => 			<div key={index}><a className="text-black font-bold">{text}</a><br/></div>, // Section Title
		"***": (text, index) => 			<div key={index}><a className="text-sky-800 cursor-pointer" onClick={(e) => {e.preventDefault();goto(text.trimStart().trimEnd().replace(/\s/g, "_"))}}>• {text}</a><br/></div>, // Subsection Title
	}

	const sideMenuElements = {
		"****": (text, index) => 			<div key={index}><a className="text-black font-bold text-sm">{text}</a><br/></div>, // Section Title
		"***": (text, index) => 			<div key={index}><a className="text-sky-800 cursor-pointer text-sm" onClick={(e) => {e.preventDefault();goto(text.trimStart().trimEnd().replace(/\s/g, "_"))}}>• {text}</a><br/></div>, // Subsection Title
	}

	const inlineElements = {
		"!NLINK": text => {
			const regex = /!NLINK\((.*?)\)\[(.*?)\]/gi;
			return text.replace(regex, (match, name, link) => {
				return `<a href="${link}" class="text-sky-700">${name}</a>`;
			});
		}, // Named link

		"!!": text => {
			const regex = /!!(.*?)!!/gi;
			return text.replace(regex, (match, name) => {
				return `<span class="font-bold">${name}</span>`;
			});
		},

		"!*": text => {
			const regex = /!\*(.*?)\*!/gi;
			return text.replace(regex, (match, name) => {
				return `<span class="font-italic">${name}</span>`;
			});
		},

		"!R": text => {
			const regex = /!\R(.*?)\R!/gi;
			return text.replace(regex, (match, name, link) => {
				return `<span class="text-red-700">${name}</span>`;
			});
		}

	}

	const reservedSymbols = {
		"****": text => 			<h1 className="font-bold text-3xl">{text}</h1>, // Section Title
		"***": text => 				
		(shareButton ? 
			<div className='inline-flex items-center'>
				<h1 id={text.trimStart().trimEnd().replace(/\s/g, "_")} className="font-bold pt-8 text-2xl">
					{text}
				</h1>
				<CopyLinkButton linkToCopy={'/documentation' + window.location.href.split('/documentation')[1].split('?')[0] + '?' + text.trimStart().trimEnd().replace(/\s/g, "_")} />
			</div>
			:
			<h1 id={text.trimStart().trimEnd().replace(/\s/g, "_")} className="font-bold pt-8 text-2xl">{text}</h1>
		),
		
		


		"**": text => 				<h1 className="font-bold pt-8 text-xl">{text}</h1>, // Subsubsection Title
		"!-": text => 				<p className="md:pl-8">• {renderText(text, "", null, true)}</p>, // Bullet Point
		"!IMGSM": text => 			<img className="md:max-w-md m-auto" src={(text.includes('https') ? text : (process.env.PUBLIC_URL + text))} alt="" />, // Small Image
		"!IMG": text => 			<img src={(text.includes('https') ? text : (process.env.PUBLIC_URL + text))} alt="" />, // Image
		"!LINK": text => 			<a className="text-sky-700 md:pl-8" href="${text}">{text}</a>, // Link
		"!NLINK": text => {
			const [link, name] = text.split("&&&&");
			return <a className="text-sky-700 md:pl-8" href={link}>{name}</a>;
		}, // Named Link
		"!SM": text => 				<span className="text-sm">{text}</span>, // Small Text
		"!CODE": text => 			<code className="text-rose-700 md:pl-8">{text}<br/></code>, // Code
		
		"!SNIPPET": () => 			{creatingSnippet = true; return ""}		, // Code
		"!ESNIPPET": () => 			{creatingSnippet = false; return <SyntaxHighlighter language={snippetLanguage} style={xonokai}>{snippet}</SyntaxHighlighter>}, // Code 

		"!TABLE": () => {creatingTable = true; return ""}, // Table

		"!TH": text => {tableHeader = text; return ""}, // Table Header
		"!TR": text => {tableRows.push(checkInlineElements(text)); return ""}, // Table Row
		
		"!BR": (text, index) => <br key={index} />, // Break Line

		"!ETABLE": () => {
			const t = createTable(tableHeader, tableRows)
			document_lines.push(t)
			creatingTable = false
			tableHeader = ''
			tableRows = []
		}, // End Table

		"__NORMALTEXT": (text, index) => 	renderText(`<p>${text}</p>`, "text-base pt-4 indent-8", index) // Normal Text
	};

	const checkInlineElements = (line) => {
		for (const symbol of Object.keys(inlineElements)){
			if (line.includes(symbol) || line.includes(symbol.toLowerCase())){
				line = inlineElements[symbol](line)
			}
		}
		return line
	}

	const renderText = (text, classes, index, span=false) => {
		if (!span){
			return <div key={index} className={classes} dangerouslySetInnerHTML={{ __html: text }} />
		}
		else {
			return <span key={index} className={classes} dangerouslySetInnerHTML={{ __html: text }} />
		}
			
	}

	// Consume symbols
	const consumeSymbols = (line) => {
		line = line.trimStart()

		for (const symbol of Object.keys(reservedSymbols)){
			if (line.startsWith(symbol) || line.startsWith(symbol.toLowerCase())){
				line = line.replace(symbol, "").replace(symbol.toLowerCase(), "")
				return { symbol, line }
			}
		}
		

	 	return { symbol: "__NORMALTEXT", line: line }; // no symbol found -> normal text
	}

	
	splitted.map((line, index) => {

		const { symbol, line: pline } = consumeSymbols(line)

		// if (pline.trim() === "" && symbol === "__NORMALTEXT") {
		// 	document_lines.push(<br key={index}/>)
		// 	return
		// } Adding space on space

		if (symbol === "!SNIPPET"){
			snippetLanguage = pline.replace("-", "").trim()
			snippet = ""
		}
		else if (symbol === "!ESNIPPET"){
			creatingSnippet = false
		}

		sectionElements[symbol] && sections.push(sectionElements[symbol](pline, index))
		sideMenuElements[symbol] && sidemenu.push(sideMenuElements[symbol](pline, index))

		if (creatingSnippet){
			snippet += line + "\n"
		}
		else {
			const processedLine = checkInlineElements(pline);
			document_lines.push(reservedSymbols[symbol](processedLine, index))
		}
	})
	return {document_lines: document_lines, sections: sections, sidemenu: sidemenu}
}

export default function DocumentationPage(props){
	const [contentParsed, setContentParsed] = useState({
		document_lines: [],
		sections: [],
		sidemenu: []
	})
	const [scrollPosition, setScrollPosition] = useState(0);

	useEffect(() => {
		if (props.content){
			setContentParsed(parseText(props.content, props.shareButton))
		}
		if (props.section){
			try{
				setTimeout(() => {
					goto(props.section)
				}, 100)
			}
			catch{}
		}

	}, [props.content, props.section])

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};

		
	}, []);

	return (
		<div>
			{props.sections && (
				<div className="block text-left mb-8">

					{contentParsed.sections.length > 0 && <p>Sections: </p>}
					{contentParsed.sections.map((element, index) => (
						element
					))}
					
				</div>
			)}
			
			
			{props.sidemenu &&
				<div className={`fixed max-w-[200px] h-auto hidden md:block w-full left-0 top-0 ${(scrollPosition > props.sidemenuTopOffset) ? 'block' : 'hidden'}`}>
					<div className={`max-w-7xl m-auto sm:ml-10 sm:mt-4  ${(scrollPosition > props.sidemenuTopOffset) ? 'block' : 'hidden'}`}>
						<div className='max-w-[170px] text-left leading-none'>
							{contentParsed.sidemenu.length > 0 && <p>Sections: </p>}
							{contentParsed.sidemenu.length > 0 && <div key={-1}><a className="text-sky-800 cursor-pointer" onClick={() => window.scrollTo(0, 0)}>Go to TOP</a><br/></div>}
							{contentParsed.sidemenu.map((element, index) => (
								element				
							))}
						</div>
						

					</div>
					
				</div>
			}
			
			
			<div className='z-10'>	
				{contentParsed.document_lines.map(element => (
					element
				))}
			</div>
		</div>
	)
}