import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import reportWebVitals from './reportWebVitals';

import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from './auth/AuthContext';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from './auth/PrivateRoute'

import Navbar from './common/Navbar'

import Login from './auth/Login'
import Register from './auth/Register'
import Passreset from './auth/Passreset'
import Passchange from './auth/Changepass'

import CollabSubmission from './auth/CollabSubmission'

import Home from './common/Home'
import Footer from './common/Footer'

import ImageTools from './tools/Imagetools'
import CatalogTools from './tools/Catalogtools'
import Tools from './tools/Tools'

import WritingDocs from './documentation/WritingDocs'

import Documentation from './documentation/Documentation'

import Profile from './user/Profile'
import Admin from './user/Admin'
import ForMembers from './user/Members'

import ScrollToTop from './common/ScrollToTop'
import SkySearch from './tools/SkySearch';

import CommunityCodes from './documentation/CommunityCodes';

import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
    <BrowserRouter>
        <AuthProvider>
          <Navbar />
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/passreset" element={<Passreset />} />
                <Route path="/changepass" element={<Passchange />} />

                <Route path="/user" element={<Profile />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/members" element={<ForMembers />} />

                <Route path="/documentation" element={<Documentation />} />
                <Route path='/documentation/:path' element={<Documentation />} ></Route>
                <Route path='/documentation/writingdocs' element={<WritingDocs />} ></Route>

                <Route path="/imagetools" element={<PrivateRoute><ImageTools /></PrivateRoute>} />
                <Route path='/imagetools/:path' element={<PrivateRoute><ImageTools /></PrivateRoute>} ></Route>


                <Route path="/tools" element={<Tools />} />
                <Route path="/catalogtools" element={<PrivateRoute><CatalogTools /></PrivateRoute>} />
                <Route path='/catalogtools/:path' element={<PrivateRoute><CatalogTools /></PrivateRoute>} ></Route>

                <Route path="/catalogtools/query" element={<PrivateRoute><CatalogTools tool = "tap" /></PrivateRoute>} />
                <Route path="/imagetools/3filter" element={<PrivateRoute><ImageTools tool = "3filter" /></PrivateRoute>} />
                
                <Route path="/externalsubmission" element={<CollabSubmission />} />
                <Route path="/skysearch" element={<SkySearch />} />

                <Route path="/community-codes" element={<CommunityCodes />} />
                <Route path="/community-codes/:path" element={<CommunityCodes />} />
                
            </Routes>
            <Footer />
        </AuthProvider>

        <ToastContainer />
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
