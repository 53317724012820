import { useState, useEffect } from 'react'
import Pyexample from './Content/Pyexample'

import Python from './Content/Python'

import DocumentationPage from './DocumentationPage'

export default function DocComponent(props){
	const [contentParsed, setContentParsed] = useState([[], []])

	const [content, setContent] = useState(props.content)

	useEffect(() => {
		if (props.content){
			if (props.content.content){
				setContent(props.content.content)
			}
		}
	}, [props])


	return(
		<div className="text-justify min-h-screen">
			<DocumentationPage 
				content={content} 
				sections={true} 
				menuelements={props.menuelements}
				sidemenu={true}
				sidemenuTopOffset={props.menuelements * 36 + 150}
				section={props.section}
				shareButton={props.shareButton}
			/>
			
		</div>
		)
}


