import React, { useState, useEffect } from 'react';
import TAP from './catalogtools/TAP';
import Querymaker from './catalogtools/Querymaker';
import Checkcoords from './catalogtools/Checkcoords';
import { Link, useMatch, useNavigate } from 'react-router-dom';

const tabs = [
  { name: 'TAP', ref: 'tap' },
  { name: 'Query Maker', ref: 'query' },
  { name: 'Check Coordinate', ref: 'checkc' },
];

export default function Tabs() {
  const [selected, setSelected] = useState('tap');
  const navigate = useNavigate();
  const params = useMatch('/catalogtools/:path');

  useEffect(() => {
    if (params) {
      setSelected(params.params.path);
    }
  }, [params]);

  return (
    <div className="bg-gray-50 h-full py-10 min-h-screen">
      <div className="container mx-auto flex flex-col sm:flex-row">
        
        <div className="w-full sm:w-1/5 px-10 mb-6 sm:mb-0 sm:px-2">
          <div className="mb-6 sm:hidden">
            <select
              value={selected}
              onChange={(e) => navigate('/catalogtools/' + e.target.value)}
              className="w-full p-3 bg-white rounded-md text-black"
            >
              {tabs.map((tab) => (
                <option key={tab.name} value={tab.ref}>{tab.name}</option>
              ))}
            </select>
          </div>

          <div className="hidden sm:block">
            <div className="flex flex-col space-y-2 mb-6">
              {tabs.map((tab) => (
                <Link
                  key={tab.name}
                  to={`/catalogtools/${tab.ref}`}
                  className={`block py-2 px-3 text-sm rounded-md ${selected === tab.ref ? 'bg-myblue text-white' : 'bg-white text-black border-black border hover:bg-myblue hover:text-white'}`}
                >
                  {tab.name}
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className="w-full sm:w-4/5 p-4 bg-white rounded shadow">
          {selected === 'tap' && <TAP />}
          {selected === 'query' && <Querymaker />}
          {selected === 'checkc' && <Checkcoords />}
        </div>
      </div>
    </div>
  );
}
