
import { useState, useEffect } from 'react'
import DocumentationPage from './DocumentationPage'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function goto($hashtag){
    const a = document.getElementById($hashtag)
    const rect = a.getBoundingClientRect()
    window.scrollTo(0, rect.top - 20)
}

export default function WritingDocs(props) {

    const [content, setContent] = useState(`\
**** Lorem Ipsum Test

*** Description of lorem
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
** Available at:
!link https://www.lipsum.com/

Here is an example of a link in the middle of the phrase !nlink(nameOfLink)[https://www.google.com/].

Example of snippet: 

!snippet - python
import pandas as pd 
import numpy as np

df = pd.read_csv('file.csv')

print(df)

!esnippet

***Table of Lorem
!table
!th LOREM**IPSUM**TEST
!tr 1**2**!nlink(salve)[https://google.com]
!tr 4**5**6
!etable

    `)

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([content], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "splus_cloud_docs.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
    
    return (
        <div className="max-w-7xl m-auto sm:p-10">
            
            <p className='font-bold text-2xl'>Writing your documentation</p>
            <br></br>

            <p>All the symbols below should come at the beginning of the line.</p>
            <table className="min-w-full divide-y divide-gray-300 text-left my-8">
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>****</td>
                        <td>Creates a 2XL (2xlarge) title, included in the "sections" only as a title, not a link.</td>
                    </tr>
                    <tr>
                        <td>***</td>
                        <td>Creates a XL (xlarge) title, included in the "sections" menu. (In the real documentation, sections leads you to the title selected.)</td>
                    </tr>
                    <tr>
                        <td>**</td>
                        <td>Creates a large bold text.</td>
                    </tr>
                    <tr>
                        <td>!-</td>
                        <td>Unordered point of list.</td>
                    </tr>
                    <tr>
                        <td>!imgsm</td>
                        <td>Small image. (!imgsm https://github.com/img)</td>
                    </tr>
                    <tr>
                        <td>!img</td>
                        <td>Page size image. (!img https://github.com/img)</td>
                    </tr>
                    <tr>
                        <td>!link</td>
                        <td>Creates a href tag link to the text of this line.</td>
                    </tr>
                    <tr>
                        <td>!nlink</td>
                        <td>Creates a href tag link but with custom name. Ex: !nlink(name)[https://path.com]</td>
                    </tr>
                    <tr>
                        <td>!sm</td>
                        <td>Small text.</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>No tag is for normal text.</td>
                    </tr>
                    <tr>
                        <td>!table</td>
                        <td>Start a table.</td>
                    </tr>
                    <tr>
                        <td>!th</td>
                        <td>Adds a table header. Elements should be separated by '**'</td>
                    </tr>
                    <tr>
                        <td>!tr</td>
                        <td>Adds a table row. Elements should be separated by '**'</td>
                    </tr>
                    <tr>
                        <td>!etable</td>
                        <td>Ends table and creates it.</td>
                    </tr>
                    <tr>
                        <td>!snippet - language</td>
                        <td>Creates a snippet block for determined language.</td>
                    </tr>
                    <tr>
                        <td>!esnippet</td>
                        <td>Ends snippet block.</td>
                    </tr>
                </tbody>
            </table>

            <p>All these other symbols may go anywhere.</p>
            <table className="min-w-full divide-y divide-gray-300 text-left my-8">
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>!nlink</td>
                        <td>Creates a href tag link but with custom name. Ex: !nlink(name)[https://path.com]</td>
                    </tr>
                    <tr>
                        <td>!! $text !!</td>
                        <td>Bold text. Ex: !!text!!</td>
                    </tr>
                    <tr>
                        <td>!* $text *!</td>
                        <td>Italic</td>
                    </tr>
                    <tr>
                        <td>!R R!</td>
                        <td>Red text</td>
                    </tr>

                </tbody>
            </table>
                 
            <p>Test writing here your docs: </p>

            <div className='grid lg:grid-cols-2'>
                <div className='relative'>
                    <textarea 
                        className='max-w-5xl w-full h-96 lg:h-full p-4 rounded mb-4'
                        placeholder='Write here your documentation'
                        onChange={(e) => setContent(e.target.value)}
                        value={content}
                        onKeyDown={(e) => {
                            if (e.key === 'Tab' && !e.shiftKey) {
                                e.preventDefault();
                                const { selectionStart, selectionEnd } = e.target;
                                const beforeTab = content.substring(0, selectionStart);
                                const afterTab = content.substring(selectionEnd);
                                const newValue = beforeTab + "\t" + afterTab;
                                setContent(newValue);

                                // Set cursor position right after the inserted tab
                                setTimeout(() => {
                                    e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
                                }, 0);
                            }
                        }}
                    >
                    </textarea>
                    <button onClick={downloadTxtFile} className="absolute right-0 p-2 bg-blue-500 text-white rounded">
                        Download as TXT
                    </button>
                </div>
                


                <div className=" p-4  outline-dashed outline-red-400">
                
                    <DocumentationPage
                        content={content}
                        sections={true}
                        sidemenu={true}
                    />
                    
                </div>

            </div>
            

        </div>
        
    )
}