import { Fragment, useEffect, useContext } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import { Link } from 'react-router-dom'
import { AuthContext } from './../auth/AuthContext'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {

	const con = useContext(AuthContext)

	useEffect(() => {
			con.testUser()
	}, [])

	return (
		<Disclosure as="nav" className="bg-white shadow">

		  {({ open }) => (
			<>
			  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

				<div className="flex justify-between h-22">
				  <div className="flex">
					<div className="flex-shrink-0 flex items-center">
						<Link to="/">
						  <img
							className="block lg:hidden h-[100px] w-auto"
							src={process.env.PUBLIC_URL + '/images/Icone_Colorido1_TextoAzulEscuro.png'}
							alt="Workflow"
						  />
						  <img
							className="hidden lg:block h-20 w-auto"
							src={process.env.PUBLIC_URL + '/images/SiglaExtenso_Colorido1_TextoAzulEscuro.png'}
							alt="Workflow"
						  />
						 </Link>
					</div>
					<div className="hidden sm:ml-6 sm:flex sm:space-x-8">
					  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
					 
					  <Link
						to="/tools"
						className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
					  >
						Tools
					  </Link>
					  <Link
						to="/documentation"
						className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
					  >
						Documentation
					  </Link>
					</div>
				  </div>
				  <div className="hidden sm:ml-6 sm:flex sm:items-center">
				  

					{/* Profile dropdown */}

					<Menu as="div" className="ml-3 relative">
					  <div>
						<Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
						  <span className="sr-only">Open user menu</span>
							{
								con.isAuth && (
									(con.user.user) && (
										<p className="text-gray-700 font-bold h-8 p-2">{con.user.user.username}</p>
									)
								)
							}
						  <img
							className={`h-8 w-8 rounded-full outline ${!con.isAuth && "outline-rose-500"} ${con.isAuth && "outline-green-500"}`}
							src={process.env.PUBLIC_URL + '/images/empty_user.png'}
							alt=""
						  />
						</Menu.Button>
					  </div>
					  <Transition
					  className = "z-100"
						enter="transition ease-out duration-200"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					  >

						{con.isAuth ? (
									<Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
									  <Menu.Item>
										{({ active }) => (
										  <Link
											to="/user"
											className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
										  >
											Your Profile
										  </Link>
										)}
									  </Menu.Item>
									  <Menu.Item>
										{({ active }) => (
										  <a href="/"
											onClick={() => con.logout()}
											className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
										  >
											Sign out
										  </a>
										)}
									  </Menu.Item>
									</Menu.Items>

								) : (
								<Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
									  <Menu.Item>
										{({ active }) => (
										  <Link
											to="/login"
											className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
											Login
										  </Link>
										)}
									  </Menu.Item>
									  <Menu.Item>
										{({ active }) => (
										  <Link
											to="/register"
											className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
											Register
										  </Link>
										)}
									  </Menu.Item>
									</Menu.Items>

							)}


						
						
						
					  </Transition>
					</Menu>
				  </div>
				  <div className="-mr-2 flex items-center sm:hidden">
					{/* Mobile menu button */}
					<Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
					  <span className="sr-only">Open main menu</span>
					  {open ? (
						<XIcon className="block h-6 w-6" aria-hidden="true" />
					  ) : (
						<MenuIcon className="block h-6 w-6" aria-hidden="true" />
					  )}
					</Disclosure.Button>
				  </div>
				</div>
			  </div>

			  <Disclosure.Panel className="sm:hidden">
				<div className="pt-2 pb-3 space-y-1">
				  <Link to="/documentation">
					  <Disclosure.Button
						className="border-transparent cursor-pointer text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
						as="a"
					  >
						Documentation
					  </Disclosure.Button>
					</Link>

				  <Link to="/tools">
					  <Disclosure.Button
					  as="a"
						className="border-transparent cursor-pointer text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
					  >
						Tools
					  </Disclosure.Button>
					 </Link>

					 <Link to="/about">
					  <Disclosure.Button
						as="a"
						className="border-transparent cursor-pointer text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
					  >
						About
					  </Disclosure.Button>
					 </Link>

				</div>
				<div className="pt-4 pb-3 border-t border-gray-200">
				  <div className="flex items-center px-4">
					<div className="flex-shrink-0">
					  <img
						className="h-10 w-10 rounded-full"
						src={process.env.PUBLIC_URL + '/images/empty_user.png'}
						alt=""
					  />
					</div>
							{con.isAuth && (
								(con.user.user) && (
									<div className="ml-3">
									  <div className="text-base font-medium text-gray-800">{con.user.user.username}</div>
									  <div className="text-sm font-medium text-gray-500">{con.user.user.email}</div>
									</div>
								)
							)}

				  </div>

							{con.isAuth ? (
								  <div className="mt-3 space-y-1">
								  <Link to="/user">
										<Disclosure.Button
											as="a"
										  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
										>
										  Your Profile
										</Disclosure.Button>
									</Link>
									<div onClick={() => con.logout()}>
										<Disclosure.Button
											as="a"
										  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
											
										>
									  Sign out
									</Disclosure.Button>

									</div>
								  </div>
								  ) : (
								  	<div className="mt-3 space-y-1">

								  <Link to="/login">
										<Disclosure.Button
											as="a"
										  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
										>
										  Login
										</Disclosure.Button>
									</Link>

									<Link to="/register">
										<Disclosure.Button
										  as="a"
										  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
										>
										  Register
										</Disclosure.Button>
									</Link>
								  </div>

								  )}
				</div>
			  </Disclosure.Panel>

			  <div className="h-[1.5px] bg-gradient-to-r from-myblue to-myred"></div>
			</>
		  )}
		</Disclosure>
	  )
}