import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';



import { Link } from 'react-router-dom'

import ServerStatus from './ServerStatus';
import News from './News'

export default function Home() {

  

  return (
    <div>
      <div className="relative text-center" style={{zIndex: -1}}>
        <div className="max-w-5xl mx-auto sm:px-6 lg:px-8">
          <div className="max-w-5xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0 ">
              <img
                className="h-[2000px] w-[2000px] absolute top-[-800px] transform translate-x-[-50%] left-[50%] object-cover"
                src={process.env.PUBLIC_URL + '/images/Globo_SemCor_AzulEscuro.png'}
                alt="grid element"
              />
              {/*<div className="absolute inset-0 bg-gradient-to-r from-myblue to-myred mix-blend-multiply" />*/}
            </div>
            <div className="relative px-4 py-12 sm:px-6 sm:py-12 lg:py-12 lg:px-8 select-none">
              <img className="mx-auto rounded-xl h-36 w-auto" src={process.env.PUBLIC_URL + '/images/Icone_Colorido1_TextoAzulEscuro.png'} alt="Workflow" />
              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="text-black">S-PLUS Cloud</span>
              </h1>
              <p className="text-black bg-white bg-opacity-70 w-auto">
                Southern Photometric Local Universe Survey Data Access Interface
              </p>
              
            </div>
          </div>
        </div>
      </div>
      
        <div className=" bg-gradient-to-b from-transparent to-gray-100">
          <ServerStatus />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:grid lg:grid-cols-2 gap-24">
            {/* Left Column: News */}
            <News />

            {/* Right Column: Community Codes */}
            <CommunityCodes />
          </div>
          
        </div>

        

        {/* Alternating Feature Sections */}
        <div className="relative overflow-hidden bg-gray-100">
          
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
          <div className="relative">
            <div className="py-12">
              <div className="mt-6 m-auto lg:max-w-2xl rounded-md lg:px-8 px-4 max-w-xl">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Search for objects
                    </h2>
                    <p className="mt-4 text-lg">
                      Search for objects and its properties in the S-PLUS database. Visualize the image and plots of the object.
                    </p>
                    <div className="mt-6">
                      <Link
                        to="/skysearch"
                        className="inline-flex bg-gradient-to-r from-myred to-myblue bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-myred hover:to-myyellow"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                        &nbsp;
                        Go to search
                      </Link>
                    </div>
                  </div>
            </div>
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                  </div>
                  
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Documentation
                    </h2>
                    <p className="mt-4 text-lg">
                      Read the full documentation on information about the S-PLUS data, including tips on how to get images, catalogues and much more.
                    </p>
                    <div className="mt-6">
                      <Link
                        to="/documentation"
                        className="inline-flex bg-gradient-to-r from-myred to-myblue bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-myred hover:to-myyellow"
                      >
                        Get started
                      </Link>
                    </div>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Tools
                    </h2>
                    <p className="mt-4 text-lg">
                      Access and download available data - images, catalogues and other information.
                    </p>
                    <div className="mt-6">
                      <Link
                        to="/tools"
                        className="inline-flex bg-gradient-to-r from-myred to-myblue bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
                      >
                        Access
                      </Link>
                    </div>
                  </div>
                  
                </div>
            
              </div>
              <ImagesSlide />
            </div>
            

          </div>
          <SplusDescription />
        </div>
        
    </div>
  )
}



function SplusDescription(){
  return (
    <div className="mt-12 py-12 bg-gray-800">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-8 lg:grid-flow-col-dense lg:gap-24">
        <div className="px-4 max-w-xl mx-auto sm:px-6 pb-12 lg:max-w-none col-span-3 lg:mx-0 lg:px-0 lg:col-start-6">
          <div>
            <div>
            </div>
            <div className="mt-6">
              <img src={process.env.PUBLIC_URL + '/images/Sigla_Colorido2_TextoBranco.png'} className="z-10 bg-gray-800" alt=""/>
            
              <p className="mt-4 text-white text-sm text-justify">
                The Southern Photometric Local Universe Survey (S-PLUS) is imaging ˜9300 deg2 of the celestial sphere in 12 optical bands (see the Figure above) using a dedicated 0.8 m robotic telescope, the T80-South, at the Cerro Tololo Inter-american Observatory, Chile. The telescope is equipped with a 9.2k × 9.2k e2v detector with 10 (μ m) pixels, resulting in a field of view of 2 deg2 with a plate scale of 0.55 arcsec pixel-1. The survey consists of four main subfields, which include two non-contiguous fields at high Galactic latitudes (|b| > 30°, 8000 deg2) and two areas of the Galactic Disc and Bulge (for an additional 1300 deg2). S-PLUS uses the Javalambre 12-band magnitude system, which includes the 5 ugriz broad-band filters and 7 narrow-band filters centred on prominent stellar spectral features: the Balmer jump/[OII], Ca H + K, H δ, G band, Mg b triplet, H α, and the Ca triplet. S-PLUS delivers accurate photometric redshifts (δz/(1 + z) = 0.02 or better) for galaxies with r higher than 19.7 AB mag and z lower than 0.4, thus producing a 3D map of the local Universe over a volume of more than 1 (Gpc/h)^3. S-PLUS data will enable, among other studies, investigating new asteroids of the Solar System, studying the star formation rates and stellar populations in and around the Milky Way and nearby galaxies, as well as searches for quasars, variable sources, and low-metallicity stars. DR1 was released in March 2019, together with the first paper of the collaboration Mendes de Oliveira et al. (2019). 
              </p>
              
            </div>
          </div>
        </div>
        <div className="px-4 max-w-xl sm:px-6 lg:py-32 lg:max-w-none m-auto col-span-5 lg:px-0 lg:col-start-1 content-center">
            <div className=" bg-white">
              <img
                style={{verticalAlign: 'middle'}}
                className="w-full z-10 rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
                src={process.env.PUBLIC_URL + '/images/survey.png'}
                alt="survey"
              />
            </div>
        </div>
      </div>
    </div>
  )
}

function CommunityCodes() {
  return (
    <div className="self-center max-w-xl m-auto mt-20 bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 h-[250px]">
      <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 mb-4">
        Community Codes
      </h2>
      <p className="text-lg mb-4">
        Explore valuable codes and scripts shared by the S-PLUS community to help with data processing and analysis.
      </p>
      <Link
        to="/community-codes" 
        className="inline-flex bg-gradient-to-r from-myred to-myblue px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-myred hover:to-myyellow"
      >
        Explore Codes
      </Link>
    </div>
  );
}

function ImagesSlide(){

  return (
    <div className="mt-12 sm:mt-16 lg:mt-0">
      <div className="sm:pl-4 lg:px-0 lg:m-0 lg:relative lg:h-full">
        <Splide
          options={ {
                    arrows: false,
                    rewind: true,
                    autoplay: true,
                    interval: 5000,
                    speed: 1000,
                    gap   : '1rem',
                  } }
        >
          <SplideSlide>
            <img
            className="max-w-auto sm:max-w-xl m-auto"
            src={process.env.PUBLIC_URL + '/images/showcase/magelanic.png'}
            alt="Inbox user interface"
          />
          </SplideSlide>
          <SplideSlide>
            <img
            src={process.env.PUBLIC_URL + '/images/showcase/dashboardbg.png'}
            className="max-w-auto sm:max-w-xl m-auto"
            alt="Inbox user interface"
          />
          </SplideSlide>
          <SplideSlide>
            <img
            className="max-w-auto sm:max-w-xl m-auto"
            src={process.env.PUBLIC_URL + '/images/showcase/NGC7089-M2_STRIPE82-0119 (1).png'}
            alt="Inbox user interface"
          />
          </SplideSlide>
          
          <SplideSlide>
            <img
            className="max-w-auto sm:max-w-xl m-auto"
            src={process.env.PUBLIC_URL + '/images/showcase/grsotnas_HYDRA-0051-153.68 - 28.91-1000_lupton.png'}
            alt="Inbox user interface"
          />
          </SplideSlide>
          <SplideSlide>
            <img
            className="max-w-auto sm:max-w-xl m-auto"
            src={process.env.PUBLIC_URL + '/images/showcase/grsotnas_STRIPE82-0060-40.4438-0.44299-1000_lupton.png'}
            alt="Inbox user interface"
          />
          </SplideSlide>
          <SplideSlide>
            <img
            className="max-w-auto sm:max-w-xl m-auto"
            src={process.env.PUBLIC_URL + '/images/showcase/mardom_STRIPE82-0059-41.604 - 0.498-700.png'}
            alt="Inbox user interface"
          />
          </SplideSlide>

        </Splide>
        
      </div>
    </div>
  )
}
