import { useState, useContext, useEffect } from 'react'
import { toast } from 'react-toastify' 
import { AuthContext } from './AuthContext'
import { useNavigate } from 'react-router-dom'

export default function Register(){

	const [username, setUsername] = useState(null)
	const [email, setEmail] = useState(null)
	const [password, setPassword] = useState(null)

	const con = useContext(AuthContext)
	const navigate = useNavigate()


	useEffect(() => {
		con.testUser()

		if(con.isAuth){
			navigate("/")
		}
	}, [con.isAuth])

	const tryRegister = () => {
		if (!username || !password || !email){

			toast.warn('Must fill all fields!')
		}
		else{
			con.register(email, username, password)
		}
	}

	return(
		<div className="space-y-6 sm:p-12 p-4 max-w-7xl m-auto">
			<div className="bg-white shadow p-12 sm:rounded-lg sm:p-6">
		        <div className="md:grid md:grid-cols-3 md:gap-6">
		          <div className="md:col-span-1">
		            <h3 className="text-lg font-medium leading-6 text-gray-900">Splus.cloud registration</h3>
		            <p className="mt-1 text-sm text-gray-500">This gives you access to all public data, catalogs, images, colored images and also access to the splusdata package.</p>
		          </div>
		          <div className="mt-5 md:mt-0 md:col-span-2">
		            <form action="#" method="POST">
		              <div className="grid grid-cols-6 gap-6">
		               

		                <div className="col-span-6 sm:col-span-3">
		                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
		                    Username (used to login!)
		                  </label>
		                  <input
		                    type="text"
		                    name="first-name"
		                    id="first-name"
		                    onChange={(e) => setUsername(e.target.value)}
		                    autoComplete="given-name"
		                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
		                  />
		                </div>

		                <div className="col-span-6 sm:col-span-4">
		                  <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
		                    Email address
		                  </label>
		                  <input
		                    type="text"
		                    name="email-address"
		                    id="email-address"
		                    onChange={(e) => setEmail(e.target.value)}
		                    autoComplete="email"
		                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
		                  />
		                </div>

		                <div className="col-span-6 sm:col-span-3">
		                  <label htmlFor="country" className="block text-sm font-medium text-gray-700">
		                    Password
		                  </label>
		                  <input
		                    type="password"
		                    onChange={(e) => setPassword(e.target.value)}
		                    autoComplete="street-address"
		                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
		                  />
		                </div>

		                <div className="col-span-6 sm:col-span-2">
    							<div className="flex">
					                <button
					                	onClick = {() => tryRegister()}
							            type="button"
							            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							          >
							            Register
							         </button>
							    </div>
							</div>

		              </div>
		            </form>
		          </div>
		        </div>
		      </div>
	    </div>
	)
}