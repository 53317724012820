import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Spin from '../common/Spinning';

import DocumentationPage from '../documentation/DocumentationPage'


export default function AdminNews() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedItem, setExpandedItem] = useState(null);
  const [expandFirst, setExpandFirst] = useState(false);

  useEffect(() => {
    async function fetchNews() {
      try {
        const response = await axios.get('/api/admin/getNews');
        setNews(response.data);
      } catch (err) {
        console.error("Error fetching news:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchNews();
  }, []);

  function linkify(text) {
    const urlRegex = /(https?:\/\/[^\s]+|(?:splus\.cloud[^\s]*))/g;
    return text.replace(urlRegex, (match, p1) => {
        // If the link starts with splus.cloud, prefix with https:// else use as it is
        const url = match.startsWith('splus.cloud') ? `https://${match}` : match;
        return `<a className="text-blue-200" href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
}


  if (loading) return <Spin />;
  if (error) return <p className="text-center text-red-600">Error loading news. Please try again later.</p>;

  return (
    <div className="m-auto w-full md:mb-4 my-4">
      <div className="max-w-5xl m-auto w-full">
		<ul role="list" className="space-y-4 w-full bg-gray-100 bg-opacity-70 py-12 px-12 rounded-lg">
      <li key={0} className="bg-white m-auto  p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 w-full max-w-lg">
				<div className="cursor-pointer flex justify-between items-center">
					<div className="flex-shrink-0">
					<h2 onClick={() => setExpandFirst(!expandFirst)} className="text-lg font-semibold text-gray-900 inline-block mr-2">Become a Member or External Collaborator</h2>
					
          </div>
					<svg onClick={() => setExpandFirst(!expandFirst)} className={`transform transition-transform duration-300 ${expandFirst ? 'rotate-180' : 'rotate-0'}`} width="16" height="16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
					</svg>
				</div>
				{expandFirst && <p className="text-sm text-gray-900 mt-2">If you want to become a member or external collaborator of S-PLUS, please create your login on https://splus.cloud (using your email and creating a password) and write to splus@iag.usp.br with the email you used to create your login. Navigate to splus.cloud/user and provide your full name and affiliation details. We will then give you access to the internal data releases and add you to the mailing list.</p>}
				</li>
			{news.map(({ id, title, date, description }) => (
				<li key={id} className="bg-white m-auto  p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 w-full max-w-lg">
				<div onClick={() => setExpandedItem(expandedItem === id ? null : id)} className="cursor-pointer flex justify-between items-center">
					<div className="flex-shrink-0">
					<h2 className="text-lg font-semibold text-gray-900 inline-block mr-2">{title}</h2>
					<p className="text-sm text-gray-500">{new Date(date).toISOString().substring(0, 10)}</p>
					</div>
					<svg className={`transform transition-transform duration-300 ${expandedItem === id ? 'rotate-180' : 'rotate-0'}`} width="16" height="16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
					</svg>
				</div>
				{expandedItem === id ? 
            <DocumentationPage
                content={description} 
                sections={true} 
                menuelements={false}
                sidemenu={false}
                sidemenuTopOffset={0}
                section={false}
                shareButton={true}
             /> : null}
				</li>
			))}
			</ul>
      </div>
    </div>
  );
}
