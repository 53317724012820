import { useState, useEffect } from 'react'
import axios from 'axios'
import { getToken } from './../../auth/token'
import { toast } from 'react-toastify'

export default function TwelveFilter(){

	const [RA, setRA] = useState('')
	const [DEC, setDEC] = useState('')
	const [R, setR] = useState('R, I, F861, Z')
	const [G, setG] = useState('G, F515, F660')
	const [B, setB] = useState('U, F378, F395, F410, F430')

	const [noise, setNoise] = useState('0.15')
	const [sat, setSat] = useState('0.15')
	const [colorsat, setColorSat] = useState('2')

	const [pixels, setPixels] = useState('')
	const [req, setReq] = useState(false)

	const [image, setImage] = useState(null)
	const [imgSrc, setImgSrc] = useState()

	const [option, setOption] = useState(1)

	const requestIm = () => {
		setReq(true)
		
		const order = R.replaceAll(" ", "") + "-" + G.replaceAll(" ", "") + "-" + B.replaceAll(" ", "")

		const data = {
			ra: RA,
			dec: DEC,
			size: pixels,
			reqOrder: order,
			noiselum: noise,
			satpercent: sat,
			colorsatfac: 1,
			option: option,
			dr: null
		}
		
		axios.post(`/api/trilogy_image`, data, {
			...getToken(),
			responseType: 'arraybuffer'
		})
		.then(response => {
			let blob = new Blob(
				[response.data], 
				{ type: response.headers['content-type'] }
			)
			var image = URL.createObjectURL(blob)
			setImgSrc(image)
			setReq(false)
		})
		.catch(error => {
			const decoded = JSON.parse(new TextDecoder("utf-8").decode(error.response.data));
			toast.error(decoded.error, error);
			setReq(false)
		})
		.catch(error => {
			toast.error("There was an error fetching the image.", error);
			setReq(false)
		});	
			
	}


	return(

		<div>
			<div className="max-w-7xl m-auto text-left mb-8">
				<h3 className="text-2xl font-bold">Create Multi-band Composite Images with Trilogy</h3>
				<p className="mb-4">
					Trilogy is a sophisticated tool (<a className='text-sky-700' href='https://www.stsci.edu/~dcoe/trilogy/Intro.html'>refer to this link</a>) designed for astronomers and astrophotographers aiming to produce high-quality images by combining data from multiple bands. It's particularly adept at managing large datasets and extracting the maximum possible detail from each band to create stunning visual representations of astronomical data.
				</p>
				<p className="mb-2">
					When generating images, you can specify the combinations of different bands, allowing for a rich and detailed composite. Please note that the image resolution is capped at a maximum of 1000 pixels to maintain optimal performance and quality.
				</p>
				<br></br>
				<p>The "option" field is in case a coordinate overlaps over two fields, use option = 2 if you want the second closest field. Alternatively, you can directly put the field name.</p>
			</div>
			<div className="py-12 px-4 md:grid md:grid-cols-3 md:gap-6 max-w-7xl m-auto">
		   		
		   	<div>
			   <div className="space-y-4">
					{/* RA Field */}
					<div className="flex items-center rounded-md shadow-sm">
						<span className="inline-flex items-center px-3 py-2 bg-gray-100 text-gray-600 border border-r-0 border-gray-300 rounded-l-md">
							RA
						</span>
						<input
							onChange={(e) => setRA(e.target.value)}
							type="text"
							className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>

					{/* DEC Field */}
					<div className="flex items-center rounded-md shadow-sm">
						<span className="inline-flex items-center px-3 py-2 bg-gray-100 text-gray-600 border border-r-0 border-gray-300 rounded-l-md">
							DEC
						</span>
						<input
							onChange={(e) => setDEC(e.target.value)}
							type="text"
							className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>

					{/* Pixels Field */}
					<div className="flex items-center rounded-md shadow-sm">
						<span className="inline-flex items-center px-3 py-2 bg-gray-100 text-gray-600 border border-r-0 border-gray-300 rounded-l-md">
							Pixels
						</span>
						<input
							onChange={(e) => setPixels(e.target.value)}
							type="text"
							className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>

					{/* RGB Fields */}
					<div className="grid xl:grid-cols-3 gap-4">
						{[['R', R, setR], ['G', G, setG], ['B', B, setB]].map(([label, value, setter]) => (
							<div className="flex items-center rounded-md shadow-sm" key={label}>
								<span className="inline-flex items-center px-3 py-2 bg-gray-100 text-gray-600 border border-r-0 border-gray-300 rounded-l-md">
									{label}
								</span>
								<input
									onChange={(e) => setter(e.target.value)}
									type="text"
									value={value}
									className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
						))}
					</div>

					{/* Stretch & Q Fields */}
					<div className="grid xl:grid-cols-2 grid-cols-1 gap-4">
						{[['Noise', noise, setNoise], ['satpercent', sat, setSat], ['Color Saturation', colorsat, setColorSat]].map(([label, value, setter]) => (
							<div className="flex items-center rounded-md shadow-sm" key={label}>
								<span className="inline-flex items-center px-3 py-2 bg-gray-100 text-gray-600 border border-r-0 border-gray-300 rounded-l-md">
									{label}
								</span>
								<input
									onChange={(e) => setter(e.target.value)}
									type="text"
									value={value}
									className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
						))}
					</div>

					{/* Option Field */}
					<div className="flex items-center rounded-md shadow-sm">
						<span className="inline-flex items-center px-3 py-2 bg-gray-100 text-gray-600 border border-r-0 border-gray-300 rounded-l-md">
							Option
						</span>
						<input
							onChange={(e) => setOption(e.target.value)}
							type="text"
							value={option}
							className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
						/>
					</div>
				</div>


					<div className="flex rounded-md mt-4">
						{!req ?
							<button
								onClick={() => requestIm()}
								type="button"
								disabled={req}
								className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full"
							>
								Request
							</button>
							:
							<div className='h-[100px] relative m-auto'>
								<div className="spin">
									<img src={process.env.PUBLIC_URL + '/images/Icone_Colorido1_TextoAzulEscuro.png'} alt="Loading" />
								</div>
							</div>
						}
						

					</div>
					</div>

					<div className="col-span-2 items-center justify-center">
						<img src={imgSrc} className="m-auto md:ml-0 pt-6 md:pt-0" alt="" />
						{imgSrc && (
							<a
								href={imgSrc}
								download={`${RA}_${DEC}_${pixels}.png`}
								className="mt-2 inline-block bg-indigo-600 text-white px-6 py-2 rounded shadow hover:bg-indigo-700 transition duration-300"
							>
								Download Image
							</a>
						)}
					</div>
				</div>
			</div>
		)
}