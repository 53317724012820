import LastQueries from './LastQueries'
import Info from './Info'
import Projects from './Projects'
import { useEffect, useState } from 'react'
import { getToken } from '../auth/token';
import { useNavigate } from 'react-router-dom';

import axios from 'axios'

export default function Profile() {

    const [collab, setCollab] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        axios.post(`/api/auth/collab`, null, getToken())
        .then(res => {
          if (res.data.collab === 'yes'){
            setCollab(true)
          }
        })
        .catch(err => {
          navigate('/login')
        })
    })
    


    return (

    <div className="max-w-7xl m-auto">
        <div className="md:grid md:grid-cols-2">
            <div>
                <Info />
            </div>
            <div>
                {collab && (
                    <Projects />
                )}
            </div>
        </div>
        


        <LastQueries />
    </div>

    )
}