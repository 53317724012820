import { useEffect, useState } from 'react';
import axios from 'axios'

export default function ServerStatus(){
    const [tap, setTap] = useState(false)
    const [server, setServer] = useState(false)
  
    const getServerStatus = () => {
      axios.get('/api')
        .catch(err => {
        
          if (err.response.status === 404){
            setServer(true)
          }
        })
      
      axios.get('/public-TAP/tap')
        .then(res => {
          if (res.status === 200){
            setTap(true)
          }
        })
  
    }
  
    useEffect(() => {
      getServerStatus()
    }, [])
  
    return (
        <div className='w-full m-auto py-8 px-4 sm:px-6 lg:px-8 '>
            <div className='max-w-3xl m-auto'>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className={`p-4 rounded border ${tap ? 'border-green-500 bg-green-100' : 'border-rose-500 bg-rose-100'}`}>
                    <div className='flex items-center'>
                        <div className={`h-4 w-4 rounded-full mr-3 ${tap ? 'bg-green-500' : 'bg-rose-500'}`}></div>
                        <p>{tap ? 'TAP Server online' : 'TAP Server offline'}</p>
                    </div>
                    </div>
                    <div className={`p-4 rounded border ${server ? 'border-green-500 bg-green-100' : 'border-rose-500 bg-rose-100'}`}>
                    <div className='flex items-center'>
                        <div className={`h-4 w-4 rounded-full mr-3 ${server ? 'bg-green-500' : 'bg-rose-500'}`}></div>
                        <p>{server ? 'splus.cloud backend online' : 'splus.cloud backend offline'}</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }