import { useState, useEffect } from 'react'
import axios from 'axios'
import { getToken } from './../../auth/token'
import { toast } from 'react-toastify'
import fetchProgress from 'fetch-progress';

import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

const BANDS = ["R", "G", "I", "U", "Z", "F378", "F395", "F410", "F430", "F515", "F660", "F861"]

export default function FitsField(){

	const [field, setField] = useState('')
	const [band, setBand] = useState('R')

	const [isWeight, setIsWeight] = useState(0)

	const [req, setReq] = useState(false)

	const requestIm = () => {
		setReq(true)
		const data = {
			fieldname: field,
			band: band,
			weight: isWeight,
			dr: null
		}
		axios.post(`/api/download_frame`, data, {...getToken(), responseType: 'arraybuffer'})
			.then(response => {
				let blob = new Blob(
					[response.data], 
					{ type: response.headers['content-type'] }
				)
				
				var url = window.URL.createObjectURL(blob);
		        var a = document.createElement('a');
		        a.href = url;
		        a.download = data['fieldname'] + "_" + data['band'] + "_" + (data['weight'] ? 'swpweight' : 'swp') + ".fits";
		        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
		        a.click();    
		        a.remove();  //afterwards we remove the element again         
				
				setReq(false)
			})
			.catch(error => {
				const decoded = JSON.parse(new TextDecoder("utf-8").decode(error.response.data));
				toast.error(decoded.error, error);
				setReq(false)
			})
			.catch(error => {
				toast.error("There was an error fetching the image.", error);
				setReq(false)
			});
			

			
	}


	return(

		<div>
			<div className="max-w-7xl m-auto px-4 text-left mb-8">
				<h3 className="text-2xl font-bold">FITS Image Retrieval from Entire Tile</h3>
				<p className="mb-4">
					Access full-tile FITS images, each spanning 11000x11000 pixels, offering a comprehensive view of the specified field. Ensure accuracy in typing the field name for precise image retrieval. This feature is ideal for extensive astronomical studies and high-resolution imaging needs.
				</p>
				<div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4">
					<p className="font-bold">Important Note</p>
					<p>
					The downloaded FITS image may be in a compressed (fpacked) format. Ensure you have the necessary tools or software to handle fpacked files for viewing or processing.
					</p>
				</div>
			</div>

			<div className="py-12 px-4 md:gap-6 max-w-xl m-auto ">
		   		
		   	<div>

			      <div className="mt-1 flex rounded-md shadow-sm">
			        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
			          Field name
			        </span>
			        <input
		        		onChange={(e) => setField(e.target.value)}
			          	type="text"
			          	className="max-w-12 x-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
			        />
			      </div>

				  <div className="mt-1 flex rounded-md shadow-sm">
			        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
			          Weight Image
			        </span>
			        <select onChange={(e) => setIsWeight(e.target.value)} className='max-w-12 x-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300'>
						<option value="0">No</option>				
						<option value="1">Yes</option>				

					</select>	

			      </div>

			      
			      <div className="mt-1 flex rounded-md shadow-sm">
				      <select
					      id="tabs"
					      name="tabs"
					      onChange={(e) => setBand(e.target.value)}
					      className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
					      defaultValue="R"
					    >
					      {BANDS.map((tab) => (
					        <option key={tab}>{tab}</option>
					      ))}
					    </select>
				    </div>

				    <div className="mt-1 flex rounded-md shadow-sm">
				      <select
					      id="tabs"
					      name="tabs"
					      onChange={(e) => setIsWeight(e.target.value)}
					      className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
					      defaultValue="R"
					    >
					    	<option key="get_cut" value="get_cut">Image</option>
					    	<option key="get_cut_weight" value="get_cut_weight">Weight Image</option>
					      
					    </select>
				    </div>

				    

				<div className="mt-1 flex rounded-md">
			        
					{!req ? <button
							onClick = {() => requestIm()}
							type="button"
							disabled={req}
							className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm w-full font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						>
							Request
						</button>
						:
						<div className='h-[100px] relative m-auto'>
							<div className="spin">
								<img src={process.env.PUBLIC_URL + '/images/Icone_Colorido1_TextoAzulEscuro.png'} alt="Loading" />
							</div>
						</div>
					}

			    </div>

		      </div>

		    </div>
	    </div>
		)
}