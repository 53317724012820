import axios from 'axios'
import { useState, useEffect } from 'react'
import { useMatch, useLocation, Link } from 'react-router-dom'
import Spin from '../common/Spinning'

import { getToken } from '../auth/token.js';

import Doc from './DocComponent'
import { toast } from 'react-toastify';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Documentation(props) {

  
  const [navigation, setNavigation] = useState([])
  const [selected, setSelected] = useState('')
  const [section, setSection] = useState('')

  const [loading, setLoading] = useState(true)
  

  const loadDocs = () => {
    var config = getToken()
    axios.get('/api/documentation/get_docs', config)
      .then(res => {
        setLoading(false)
        setNavigation(res.data)
        if (!params){
          setSelected(res.data[res.data.length - 1].ref) 
          
        }
        
      })
      .catch(err => {
        console.log(err)
        toast.error('Unable to load docs, check if server is online.')
      })
  }

  const params = useMatch('/documentation/:path*')
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.size > 0){
      setSection(Object.keys(Object.fromEntries(searchParams))[0])
    }else{
      setSection('')
    }

    if (params){
      setSelected(params.params.path)
    }
  }, [params])

  useEffect(() => {
    loadDocs()
  }, [])
  

  return (
    
    <div className="md:grid md:grid-cols-4 max-w-7xl m-auto sm:p-10 overflow-auto">
        <nav className="space-y-1 md:cols-span-1 " aria-label="Sidebar">
            {loading && <Spin />}
            {navigation.map((item) => (
              
              <Link
                key={item.name}
                to={'/documentation/' + item.ref}
                className={classNames(
                  (selected === item.ref) ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                  'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
                )}
                aria-current={(selected === item.ref) ? 'page' : undefined}
                onClick={() => setSelected(item.ref)}
              >
                <span className="truncate">{item.name}</span>
                
              </Link>
            ))}
            <Link 
              to='/documentation/writingdocs'
              className={classNames(
                'text-gray-900 hover:bg-gray-50 hover:text-gray-600',
                'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
              )}
            >Write your documentation.
            </Link>
          
        </nav>

        <div className="md:col-span-3 p-4 z-0">
            <Doc shareButton={true} content={navigation.length > 0 ? navigation.find((opt) => (opt.ref === selected)) : null} tab={selected} menuelements={navigation.length} section={section} />
        </div>

    </div>
    
  )
}