import React, { useState } from 'react';
import axios from 'axios';
import { getToken } from '../../auth/token.js';
import { toast } from 'react-toastify';

function Checkcoords() {
  const [coords, setCoords] = useState({ dec: '', ra: '' });
  const [res, setRes] = useState(null);
  const [loading, setLoading] = useState(false);

  const onChange = e => {
    setCoords({ ...coords, [e.target.name]: e.target.value });
    setRes(null);
  };

  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const config = getToken();
      const response = await axios.post(`/api/check_near_field`, coords, { headers: { 'Authorization': config.headers['Authorization'] } });
      setRes(response.data);
    } catch (err) {
      toast.error("An error occurred");
      setRes(undefined);
    } finally {
      setLoading(false);
    }
  };

  const renderButton = () => (
    <button
      onClick={onSubmit}
      disabled={loading}
      className="bg-gradient-to-r from-myred to-myyellow text-black px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm hover:from-purple-700 hover:to-indigo-700 disabled:opacity-50"
    >
      {loading ? 'Processing...' : 'Request'}
    </button>
  );

  return (
    <div className="max-w-5xl m-auto">
      <h3 className="text-2xl font-bold mb-4">Check if Coordinates are in Any Data Release</h3>
      <p>Insert RA and DEC in degrees to check if they are present in any data release.</p>

      <div className='w-full max-w-xs mb-4'>
        <div className="mt-1 flex rounded-md shadow-sm">
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            RA
          </span>
          <input
            onChange={onChange}
            name="ra"
            type="text"
            className="flex-1 min-w-0 block w-full px-3 py-2 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
          />
        </div>

        <div className="mt-1 flex rounded-md shadow-sm">
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            DEC
          </span>
          <input
            onChange={onChange}
            name="dec"
            type="text"
            className="flex-1 min-w-0 block w-full px-3 py-2 rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"
          />
        </div>
      </div>

      <div className="mb-4">{renderButton()}</div>

      {res && (
        <div className="mt-6">
          <h4 className="font-bold">Field Name:</h4>
          <p>{res.field || 'N/A'}</p>

          <p>{res.public ? `Data Release: ${res.public} (And all releases above)` : 'Not in any Data Release'}</p>
          <p>{res.internal ? `Internal Data Release: ${res.internal} (And all releases above)` : 'Not in any Internal Data Release'}</p>

          <h4 className="font-bold">Distance from the Center of the Tile:</h4>
          <p>{res.distance ? `${res.distance} Degrees` : 'N/A'}</p>

          {res.distance && res.distance > 0.7 && <p className="text-sm text-red-500">If the distance is bigger than 0.7 degrees, there is a good chance that the coordinates are outside the tile.</p>}
        </div>
      )}
    </div>
  );
}

export default Checkcoords;
