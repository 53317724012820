import { useEffect, useState } from 'react'
import { getToken } from '../auth/token'
import { DownloadIcon } from '@heroicons/react/outline'

import axios from 'axios'


import Spin from '../common/Spinning'

export default function LastQueries() {

	const [res, setRes] = useState([])
  const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		const config = getToken()
		axios.get(`/api/getlastqueries` ,{headers: { 'Authorization': config.headers['Authorization'] }})
        .then(res => {
            setRes(res.data.return)
            setLoaded(true)
        })
	}, [])

    function getStatus(event, queryid) {
        var config = getToken()
        axios.get(queryid ,{headers: { 'Authorization': config.headers['Authorization'] }})
        .then(res => {
            if (res.data.phase === "COMPLETED"){
            	if (!event.target.innerText.toString().includes('ownload')){
            		event.target.innerText = ''
                 	event.target.innerHTML = `<button>${res.data.phase} <a download href="${res.data.results[0].href.replace("http://10.180.0.208:8080", "https://splus.cloud")}"> Download</a></button>`
            	}
           	}
            else{
            	event.target.innerText = `${res.data.phase}`
            }
        })
        .catch(err => {
            event.target.innerText = 'EXPIRED'
            event.target.disabled = true
            event.target.onclick = ''
        })
    }

	return(
    <div className="px-4 sm:px-6 lg:px-8 mt-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Last Queries</h1>
          <p className="mt-2 text-sm text-gray-700">
            Last 10 queries. Remember that queries expire in 2 hours.
          </p>
          <div>{!loaded && <Spin />}</div>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Query
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {res.map((r) => (
                    <tr key={r.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {r.date}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {r.query}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <div>
                        	<a onClick={(e) => getStatus(e, r.queryid)} className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
	                          Get Info
	                        </a>
                        </div>
                        
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>)
}