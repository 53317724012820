import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'

import { toast } from 'react-toastify'

import { getToken } from './token'

export default function CollabSubmission() {
  
  const [memberInfo, setMemberInfo] = useState(null)
  const [projectTitle, setProjectTitle] = useState(null)
  const [projectPi, setProjectPi] = useState(null)
  const [motivation, setMotivation] = useState(null)
  const [comments, setComments] = useState(null)

  const nav = useNavigate()

  const submit = () => {
    const data = {memberInfo, projectTitle, projectPi, motivation, comments}

    axios.post('/api/user/submitRequest', data, getToken())
      .then(res => {
        if (res.data.error){
          toast.error(res.data.error)
        }else{
          toast.sucess(res.data.msg)
          nav('/')
        }
      })
      .catch(err => {
        toast.error('An error occurred')
      })

  } 

  return (
    <div className="sm:p-4 md:px-6 lg:px-8 w-full m-auto">
        <div className="shadow m-auto rounded">
          <div className="pr-2">
            <p className="p-2 pb-0 font-bold">S-PLUS External Collaborator Request</p>
            <p className="p-2 pt-0  text-sm text-rose-500">Request external collaborators for your S-PLUS project. Only full members of S-PLUS can request external collaborators. External collaborators are welcome on a per-project basis. If your external collaborator participates in multiple projects, please submit a request for each project.</p>
            <p className="text-sm pl-2 mt-4">Member Info</p>
            <textarea
                  rows={2}
                  value={memberInfo}
                  onChange={(e) => setMemberInfo(e.target.value)}
                  className="shadow-sm m-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
                <p className="text-sm pl-2">Project Title</p>
                <textarea
                  rows={4}
                  value={projectTitle}
                  onChange={(e) => setProjectTitle(e.target.value)}
                  className="shadow-sm m-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
                <p className="text-sm pl-2">Project Pi</p>
                <textarea
                  rows={2}
                  value={projectPi}
                  onChange={(e) => setProjectPi(e.target.value)}
                  className="shadow-sm m-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
                <p className="text-sm pl-2">Motivation</p>
                <textarea
                  rows={2}
                  value={motivation}
                  onChange={(e) => setMotivation(e.target.value)}
                  className="shadow-sm m-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
                <p className="text-sm pl-2">Comments</p>
                <textarea
                  rows={2}
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  className="shadow-sm m-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <button className="m-2 p-2 bg-gray-300 rounded" onClick={() => submit()}>Submit</button>
        </div>
      </div>
    );
}