import React from 'react'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import './TAP.css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/sql/sql'
import axios from 'axios'
import { getToken } from '../../auth/token';
import { AuthContext } from '../../auth/AuthContext'

import ResultsTab from './ResultsTab'
import { Link } from 'react-router-dom';

import SpinLoad from "../../common/Spinning"
import Examplescol from './Examplescol'

if (process.env.NODE_ENV==="development"){
  var IP = 'https://splus.cloud'
}else{
  IP = ''
}

              // <div className="col" onClick={() => {this.editor.getDoc().replaceRange(`Select * FROM idr3.detection_image`, this.editor.getDoc().getCursor())}}>
              //   <p>Select * FROM idr3.detection_image</p>
              // </div>

class RouteTAP extends React.Component {
  static con = AuthContext;
  isComponentMounted: boolean = false;

  constructor(props) {
    super(props)

    this.state = {
      structure: {}, // database structure
      currentSchema: '', // current selected database schema
      currentTable: '', // current selected database table
      currentColumn: '', // current selected database
      query: '',
      responseFormat: '',
      execMode: 'async',
      currentTab: 0,
      checked: false,
      header: "application/x-www-urlencoded",
      file: '',
      isLoading:true,
      taplink: '',
      iscollab: false,
      excols: [0, 0]
    }

    this.editor = null
    //this.api = new API()

    this.parseSchemas = this.parseSchemas.bind(this)
    this.getSchemaArray = this.getSchemaArray.bind(this)
    this.getTableArray = this.getTableArray.bind(this)
    this.getColumnArray = this.getColumnArray.bind(this)
    this.handleQuery = this.handleQuery.bind(this)
    this.handleSyncQuery = this.handleSyncQuery.bind(this)
    this.handleAsyncQuery = this.handleAsyncQuery.bind(this)
    this.renderQueryTab = this.renderQueryTab.bind(this)
  }

  parseSchemas(xmlString) {
    const structure = {}
    const parser = new DOMParser()
    const dom = parser.parseFromString(xmlString, 'application/xml')

    const schemas = dom.documentElement.getElementsByTagName('schema')
    for (let schema of schemas) {
      const schemaName = schema.querySelector('name').textContent
      structure[schemaName] = {}

      const tables = schema.querySelectorAll('table')
      for (let table of tables) {
        const tableName = table.querySelector('name').textContent
        structure[schemaName][tableName] = []

        const columns = table.querySelectorAll('column')
        for (let column of columns) {
          const columnName = column.querySelector('name').textContent
          structure[schemaName][tableName].push(columnName)
        }
      }
    }
    return structure
  }

  getSchemaArray() {
    if (Object.keys(this.state.structure).length === 0) return
    return Object.keys(this.state.structure).map((schema, i) => (
      <span key={'schema:' + schema}
        onClick={() => this.setState({ currentSchema: i, currentTable: 0, currentColumn: 0 })}
        onDoubleClick={() => this.editor.getDoc().replaceRange(`"${schema}"`, this.editor.getDoc().getCursor())}
        className={`py-1 px-2 max-h-[32px] cursor-pointer select-none ${i === this.state.currentSchema ? 'bg-blue-200 text-black' : ''}`}>
        {schema}
      </span>
    ))
  }

  getTableArray() {
    if (Object.keys(this.state.structure).length === 0) return
    const schema = Object.keys(this.state.structure)[this.state.currentSchema]
    return Object.keys(this.state.structure[schema]).map((table, i) => (
      <span key={'table:' + table}
        onClick={() => this.setState({ currentTable: i, currentColumn: 0 })}
        onDoubleClick={() => this.editor.getDoc().replaceRange(`"${Object.keys(this.state.structure)[this.state.currentSchema]}"."${table}"`, this.editor.getDoc().getCursor())}
        className={`py-1 px-2 max-h-[32px] cursor-pointer select-none ${i === this.state.currentTable ? 'bg-blue-200 text-black' : ''}`}>
        {table}
      </span>
    ))
  }

  getColumnArray() {
    if (Object.keys(this.state.structure).length === 0) return
    const schema = Object.keys(this.state.structure)[this.state.currentSchema]
    const table = Object.keys(this.state.structure[schema])[this.state.currentTable]
    return this.state.structure[schema][table].map((column, i) => (
      <span key={'column:' + column}
        onClick={() => this.setState({ currentColumn: i })}
        onDoubleClick={() => this.editor.getDoc().replaceRange(`"${column}"`, this.editor.getDoc().getCursor())}
        className={`py-1 px-2 max-h-[32px] cursor-pointer select-none ${i === this.state.currentColumn ? 'bg-blue-200 text-black' : ''}`}
      >
        {column}
      </span>
    ))
  } 

  async handleSyncQuery(url, body) {
    try {
      var config = getToken()

      let res = await fetch(url, {
        headers: {
          'Content-Type': "application/x-www-urlencoded",
          'Accept': 'application/xml', 
          'Authorization': config.headers['Authorization']
        },
        method: 'post',
      })

      if(res.status === 200) {
       setTimeout(() => {
         window.location.href = res.url;
       }, 100);
      }
        
    } catch (err) {
    }
  }

  async handleAsyncQuery(url, body) {
    var config = getToken()

    try {
      let resp = await fetch(url, {
        headers: {
          'Accept': 'application/xml',
          'Authorization': config.headers['Authorization']
        },
        method: 'post',
        body: body
      })

      resp = await resp

      axios.get(resp.url, {headers: { 'Authorization': config.headers['Authorization'] }})
        .then(res => {
            const job = res.data
            this.setState({
              queryStatus: job,
              currentTab: 1
            })
          }).catch(err => {
            console.log(err)
        });

    } catch (err) {
    }
  }

  handleQuery() {
    if (!this.state.checked) {
      var data = {
      request: 'doQuery',
      version: '1.0',
      lang: 'ADQL',
      phase: 'run',
      query: encodeURIComponent(this.state.query),
      format: this.state.responseFormat
      }

    } else {
      data = {
      request: 'doQuery',
      version: '1.0',
      lang: 'ADQL',
      phase: 'run',
      query: encodeURIComponent(this.state.query),
      format: this.state.responseFormat,
      upload: 'upload,param:uplTable'
      }

      var formData = new FormData()
      formData.append("uplTable", this.state.file)
      
    }

    const qs = Object.entries(data).map(([k, v]) => `${k}=${v}`).join('&')
    const finalUrl = `${this.state.taplink}/${this.state.execMode}/?${qs}`

    if (this.state.execMode === 'sync') {
      this.handleSyncQuery(finalUrl, formData)
    } else if (this.state.execMode === 'async') {
      this.handleAsyncQuery(finalUrl, formData)
    }
  }
  
  handleFileChange = e => {
      this.setState({
        [e.target.name]: e.target.files[0],
      })
  }

  handleChangeCheck = event => {
    this.setState({ checked: event.target.checked })
    if (this.state.checked){
      this.setState({ header: "application/x-www-urlencoded", execMode:'sync' })
    }else {
      this.setState({ header: "multipart/form-data", execMode:'async' })
    }
  }

  componentDidMount() {
    this.isComponentMounted = true;
    var config = getToken()
    var link = ''

    const cols = Examplescol()
    this.setState({excols: cols})

    if(this.context.isCollab){
      this.setState({
        taplink: `${IP}/tap/tap`,
        iscollab: true
      })
      link = `${IP}/tap/tap`
      
    }else{
      this.setState({
        taplink: `${IP}/public-TAP/tap`
      })
      link = `${IP}/public-TAP/tap`

      axios.post(`${IP}/api/auth/collab`, null, config)
        .then(res => {
          if (res.data.collab === 'yes'){
            this.setState({
              iscollab: true
            })
          }
      })
    }

    fetch(`${link}/tables`, {
        headers: {
          'Authorization': config.headers['Authorization'],
        }})
      .then(r => r.text())
      .then(xmlString => {
        const structure = this.parseSchemas(xmlString)
        this.setState({ structure, currentSchema: 0, currentTable: 0, currentColumn: 0, isLoading: false })
      })
      .catch(console.log)
  }

  componentWillUnmount() {
      this.isComponentMounted = false;
  };

  changeToInternal(){
    var config = getToken()
    this.setState({
      taplink: `${IP}/tap/tap`,
      isLoading: true,
      structure: {},
    })

    var link = `${IP}/tap/tap`

    fetch(`${link}/tables`, {
        headers: {
          'Authorization': config.headers['Authorization'],
        }})
      .then(r => r.text())
      .then(xmlString => {
        const structure = this.parseSchemas(xmlString)
        this.setState({ structure, currentSchema: 0, currentTable: 0, currentColumn: 0, isLoading: false })
      })
      .catch(console.log)
  }

  renderQueryTab() {
    return (
        <div className="max-w-6xl m-auto shadow p-10 pt-0">
        <Link to="/documentation/examples"><p className="ml-5">Examples HERE</p></Link>
        {
          (this.state.iscollab) ?
          <div className="mx-5 my-4">
            <div className="grid-rows-1">

            {(this.state.taplink.includes("public")) ?
              <div className="col">
                  <Link to="/catalogtools"><p style={{cursor: 'pointer'}} className="text-sky-800" onClick={() => this.changeToInternal()}>Access internal data</p></Link>
                </div>
              :
              <div>
              <div className="col">
                <h4>Accessing internal data</h4>
              </div>

                <div className="col">
                  <p style={{cursor: 'pointer'}} className="text-sky-800" onClick={() => document.location.reload(true)}>Access public data</p>
                </div>
              </div>
            }


              
            </div>
            <p className="text-rose-700">Last queries on profile</p>
          </div>
          :
          ''
        }


        <div className="md:grid md:grid-cols-3">
          <div className="h-[300px] max-w-[300px] w-full m-auto p-1">
            <span>Schemas</span>
            <div className="outline outline-1 rounded outline-gray-400 h-[250px]">
              <div className="grid overflow-y-scroll max-h-[250px]">
                {this.getSchemaArray()}
              </div>
            </div>
          </div>

          <div className="h-[300px] max-w-[300px] w-full m-auto p-1">
            <span>Tables</span>
            <div>
              <div className="outline outline-1 rounded outline-gray-400 h-[250px]">
                {this.state.isLoading ?
                  <div className="grid overflow-y-scroll h-[250px] outline outline-1 rounded outline-gray-800">
                    <SpinLoad />
                  </div>
                  :
                  <div className="grid overflow-y-scroll max-h-[250px]">
                  {this.getTableArray()}
                </div>
                }
              </div>
            </div>
          </div>

          <div className="h-[300px] max-w-[300px] w-full m-auto p-1">
            <span>Columns</span>
            <div className="outline outline-1 rounded outline-gray-400 h-[250px]">
              <div className="grid overflow-y-scroll  max-h-[250px]">
                {this.getColumnArray()}
              </div>
            </div>
          </div>
        </div>

        <div className="md:grid md:grid-cols-8 md:gap-6">
          <div className="col-span-6 lg:px-4">
            <span>ADQL Query</span>
            <CodeMirror
              options={{ mode: 'sql', lineNumbers: true, lineWrapping: true }}
              editorDidMount={editor => this.editor = editor}
              className="text-base"
              onChange={(editor, data, value) =>{ 
                this.setState({ query: value })}
              } />
          </div>

          <div className="col-span-2" id="examplescol">
            <p>Add example to query editor</p>
            {this.state.excols.map(ex => (
              <div key={ex.title}>
                <Link to="#" className="text-sky-800" onClick={() => this.editor.getDoc().replaceRange(ex.query, this.editor.getDoc().getCursor())}><small>{ex.title}</small></Link>
                <br/>
              </div>
            ))}
          </div>
        </div>

        <div className="px-4 mt-4">
            <div className="form-inline">
              <div className="grid">
                <label className="mr-2">Format</label>
                <select className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-[150px] shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" onChange={e => this.setState({ responseFormat: e.target.value })}>
                  <option value="application/x-votable+xml">votable</option>
                  <option value="application/x-votable+xml;serialization=BINARY2">votable/b2</option>
                  <option value="application/x-votable+xml;serialization=TABLEDATA">votable/td</option>
                  <option value="application/x-votable+xml;serialization=FITS">votable/fits</option>
                  <option value="application/fits">fits</option>
                  <option value="application/json">json</option>
                  <option value="text/csv">csv</option>
                  <option value="text/tab-separated-values">tsv</option>
                  <option value="text/plain">text</option>
                  <option value="text/html">html</option>
                </select>
              </div>

              <div className="form-group">
                <label className="mr-2">Execution Mode</label>
                <select className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-[150px] shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" id="execmode" onChange={e => this.setState({ execMode: e.target.value })}>
                  {!this.state.checked ? 
                    <>
                    <option value="async">Async</option> 
                    <option value="sync">Sync</option>
                    </>

                  : <option value="async">Async</option> } 
                  

                </select>

                <label>Mark to upload table:  <input className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" type="checkbox" defaultChecked={this.state.checked} onChange={this.handleChangeCheck} /></label>
                <input disabled={!this.state.checked} name="file" onChange={this.handleFileChange} className="sm:ml-6 relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" type="file" />
              </div>

              <button className="inline-flex bg-gradient-to-r from-myred to-myyellow text-black bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black hover:from-purple-700 hover:to-indigo-700" onClick={() => this.handleQuery()}>Submit</button>
            </div>
          </div>
      </div>
    )
  }


  render() {
    let tab
    if (this.state.currentTab === 0) {
      tab = this.renderQueryTab()
    } else if (this.state.currentTab === 1) {
      tab = <ResultsTab status={this.state.queryStatus} execMode={this.state.execMode} link={this.state.taplink} />
    }
    return (
      <>

        <div className="m-auto max-w-5xl px-5">
          <h6 className="ml-5">ADQL Query</h6>  

          <p className="text-yellow"></p>

          <ul className="flex ml-5">
            <li className={`px-4 py-2 rounded-b-none rounded border border-b-0 cursor-pointer ${`${this.state.currentTab === 0 ? 'bg-gray-100' : ''}`}`}>
              <p className={`${this.state.currentTab === 1 ? 'text-black' : ''}`} onClick={() => this.setState({ currentTab: 0 })}>Query</p>
            </li>
            <li className={`px-4 py-2 rounded-b-none rounded border border-b-0 cursor-pointer ${`${this.state.currentTab === 1 ? 'bg-gray-100' : ''}`}`}>
              <p className={`${this.state.currentTab === 0 ? 'text-black' : ''}`} onClick={() => this.setState({ currentTab: 1 })}>Results</p>
            </li>
          </ul>
          <div className="tab border-left border-bottom border-right pt-3 bg-gray-100 rounded">
            {tab}
          </div>

          <div style={{ height: '70px' }}></div>
        </div>
      </>
    )
  }
}

export default RouteTAP;