function Examplescol(){
	const examples = [
		{
			title: 'Cone Search',
			query: `SELECT ra, dec FROM dr1.all_dr1 
			WHERE 1 = CONTAINS( POINT('ICRS', ra, dec), 
			CIRCLE('ICRS', INPUT_RA, INPUT_DEC, INPUT_RADIUS) )

			-- Change INPUTS with your values`
		},
		{
			title: 'Upload VOTable Crossmatch',
			query: `SELECT TOP 10 * FROM "dr2"."reference" 
			AS ref JOIN TAP_UPLOAD.upload AS upl 
			ON 1=CONTAINS( POINT('ICRS', ref.ra, ref.dec), 
			CIRCLE('ICRS', upl.ra, upl.dec, 0.000277777777778))

			-- Obs: Upload only accepts VOtables. 
			-- Radius range is set to 0.000277777777778 aprox 1arcsec`
		},
		{
			title: 'Joining all dr2 tables',
			query: `select top 1000 det.id, det.ra, det.dec,
			u.u_iso, z.z_iso, g.g_iso, r.r_iso, i.i_iso,
			j0378.j0378_iso, j0395.j0395_iso, j0410.j0410_iso, 
			j0430.j0430_iso, j0515.j0515_iso,  j0660.j0660_iso, 
			j0861.j0861_iso from dr2.detection_image as det join
			dr2.g_band as g on g.id = det.id join
			dr2.z_band as z on z.id = det.id join
			dr2.r_band as r on r.id = det.id join
			dr2.i_band as i on i.id = det.id join
			dr2.u_band as u on u.id = det.id join
			dr2.j0378_band as j0378 on j0378.id = det.id join
			dr2.j0395_band as j0395 on j0395.id = det.id join
			dr2.j0410_band as j0410 on j0410.id = det.id join
			dr2.j0430_band as j0430 on j0430.id = det.id join
			dr2.j0515_band as j0515 on j0515.id = det.id join
			dr2.j0660_band as j0660 on j0660.id = det.id join
			dr2.j0861_band as j0861 on j0861.id = det.id `
		},
	]

	return examples
}

export default Examplescol