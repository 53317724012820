import React, { useState, useEffect } from 'react';
import OneFilter from './imagetools/1filter';
import ThreeFilter from './imagetools/3filter';
import TwelveFilter from './imagetools/12filter';
import FitsCut from './imagetools/Fitscut';
import FitsField from './imagetools/Fitsfield';
import { Link, useMatch, useNavigate } from 'react-router-dom';

const categories = [
  {
    label: 'Images (PNG)',
    tabs: [
      { label: '1 filter - lupton', value: '1filter' },
      { label: '3 filter - lupton', value: '3filter' },
      { label: '12 filter - trilogy', value: '12filter' }
    ]
  },
  {
    label: 'FITS',
    tabs: [
      { label: 'stamp', value: 'FITScut' },
      { label: 'field', value: 'FITStile' }
    ]
  }
];

const Tab = ({ label, value, isSelected, onClick }) => (
	<Link
	  to={`/imagetools/${value}`}
	  onClick={() => onClick(value)}
	  className={`block py-2 px-3 text-sm rounded-md ${isSelected ? 'bg-myblue text-white font-bold' : 'bg-white font-semibold text-black border-black border hover:bg-myblue hover:text-white'}`}
	>
	  {label}
	</Link>
  );

export default function Tabs() {
  const [selected, setSelected] = useState('1filter');
  const navigate = useNavigate();
  const params = useMatch('/imagetools/:path');

  useEffect(() => {
    if (params) {
      setSelected(params.params.path);
    }
  }, [params]);

  const handleTabClick = (value) => {
    setSelected(value);
    navigate(`/imagetools/${value}`);
  };


  return (
    <div className="bg-gray-50 min-h-screen h-full py-10">
      <div className="container mx-auto flex flex-col sm:flex-row">

        <div className="w-full sm:w-1/5 px-10 mb-6 sm:mb-0 sm:px-2">
          <div className="mb-6 sm:hidden">
            <select
              value={selected}
              onChange={(e) => handleTabClick(e.target.value)}
              className="w-full p-3 bg-white rounded-md text-black"
            >
              {categories.flatMap(category => 
                category.tabs.map(tab => (
                  <option key={tab.value} value={tab.value}>
                    {tab.label}
                  </option>
                ))
              )}
            </select>
          </div>

          <div className="hidden sm:block">
            {categories.map(category => (
              <div key={category.label} className="mb-8">
                <h2 className="text-lg font-semibold mb-3 text-black">{category.label}</h2>
                <div className="flex flex-col space-y-2 mb-6">
                  {category.tabs.map(tab => (
                    <Tab key={tab.label} {...tab} isSelected={selected === tab.value} onClick={handleTabClick} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full sm:w-4/5 p-4 bg-white rounded shadow">
          {selected === '1filter' && <OneFilter />}
          {selected === '3filter' && <ThreeFilter />}
          {selected === '12filter' && <TwelveFilter />}
          {selected === 'FITScut' && <FitsCut />}
          {selected === 'FITStile' && <FitsField />}
        </div>
      </div>
    </div>
  );
}
