import React from 'react';
import axios from 'axios';
import { getToken } from '../auth/token'

import { toast } from 'react-toastify'

import AdminNews from './AdminNews'


class Admin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: [],
            opened: [],
            submissions: [],
            submissionOpened: -1
        }
    }

    componentDidMount(){
        var config = getToken()

        axios.get('/api/project/projectsToApprove', config)
            .then(res => { 
                this.setState({projects: res.data})
            })

        this.getSubmissions()
    }

    getUsers(){
        var config = getToken()
        axios.get('/api/getMembersStatus', config)
            .then(res => {
                this.setState({users: res.data})
            })
    }

    showItem = (item, cont) => {
        var opened = this.state.opened
        if (!opened.includes(cont)){

          opened.push(cont)
          this.setState({opened: opened})
          const div = document.getElementById(`singleobj${cont}`)

          console.log(item)
          const row1 = document.createElement('div')
          row1.className = 'row'
          row1.innerHTML = `
                            <div class="col" style="max-width: 150px"><small>Description: </small> </div>
                            <div class="col"><small>${item.description}<br><br></small></div>

          ` 

          const row5 = document.createElement('div')
          row5.className = 'row'
          row5.innerHTML = `
                            <div class="col" style="max-width: 150px"><small>keywords: </small> </div>
                            <div class="col"><small>${item.keywords}</small></div>
          `

          const row2 = document.createElement('div')
          row2.className = 'row'
          row2.innerHTML = `
                            <div class="col" style="max-width: 150px"><small>Pi: </small> </div>
                            <div class="col"><small>${item.pi}</small></div>
          ` 

          const row4 = document.createElement('div')
          row4.className = "row"
          row4.innerHTML = `
                            <div class="col" style="max-width: 150px"><small>Pi Email: </small> </div>
                            <div class="col"><small>${item.pi_email}</small></div>
          `

          const row6 = document.createElement('div')
          row6.className = "row"
          row6.innerHTML = `
                            <div class="col" style="max-width: 150px"><small>Date: </small> </div>
                            <div class="col"><small>${item.date}</small></div>
          `

          div.appendChild(row1)
          div.appendChild(row5)
          div.appendChild(row2)
          div.appendChild(row4)
          div.appendChild(row6)



          const row3 = document.createElement('div')
          row3.className = 'row'
          row3.style.display = "flex"
          const approveBut = document.createElement('button')
          approveBut.style.width = "200px"
          approveBut.style.margin = "10px auto 20px auto"
          approveBut.className = 'p-2 bg-green-500 rounded'
          approveBut.innerText = "Approve"
          approveBut.addEventListener('click', () => this.approve(item.title))
          row3.appendChild(approveBut)

          const deleteBut = document.createElement('button')
          deleteBut.style.width = "100px"
          deleteBut.style.margin = "10px auto 20px auto"
          deleteBut.className = 'p-2 bg-rose-500 rounded'
          deleteBut.innerText = "Delete"
          deleteBut.addEventListener('click', () => this.delete(item.title))

          row3.appendChild(deleteBut)

          div.appendChild(row3)
// 
        }else{

        }
      }

    approve = (title) => {
        const config = getToken()
        const data = {title: title}
        axios.post('/api/project/approveProject', data, config)
        .then(res => {
            try{
                toast.success('Project Approved!')
                window.location.reload();
            }catch{
                toast.error(res.data.error)
            }
        })
    }

    delete = (title) => {
        const config = getToken()
        const data = {title: title}
        if (window.confirm('Delete the item? This is irreversible.')){
            axios.post('/api/project/deleteProject', data, config)
            .then(res => {
                try{
                    toast.success('Project Deleted!')
                    window.location.reload();
                }catch{
                    toast.error(res.data.error)
                }
            })
        }
    }

    changePermission = (id) => {
        const config = getToken()
        const value = document.getElementById(`permission${id}`).value

        const data = {'id': id, 'level': value}
        axios.post('/api/project/addPi', data, config)
        .then(res => {
            try{
                toast.success(res.data.msg)
                document.getElementById(`itemMember${id}`).innerText = value
                console.log(document.getElementById(`itemMember${id}`))
            }catch{
                toast.error(res.data.error)
            }
        })
    }

    getSubmissions = () => {
        axios.get('/api/user/getRequests', getToken())
            .then(res => {
                console.log(res)
                this.setState({submissions: res.data})
            })
    }

    approveSubmission = (id) => {
        axios.post('/api/user/approveRequest', {id}, getToken())
            .then(res => {
                try{
                    toast.success(res.data.msg)
                    this.getSubmissions()
                }catch{
                    toast.error(res.data.error)
                }
            })
    }
    denySubmission = (id) => {
        axios.post('/api/user/denyRequest', {id}, getToken())
            .then(res => {
                try{
                    toast.success(res.data.msg)
                    this.getSubmissions()
                }catch{
                    toast.error(res.data.error)
                }
            })
    }
    openSubmission = (id) => {
        if (this.state.submissionOpened === id){
            this.setState({submissionOpened: -1})
        }else{
            this.setState({submissionOpened: id})
        }
    }

    render() {
        return (
            <div className="max-w-5xl m-auto">
                {this.state.projects ? 
                    <div> 
                                    <div><h3 className="font-bold text-center text-3xl mt-8">Projects to approve</h3></div>
                                        <div></div>

                                        {(this.state.projects !== {} && !this.state.projects.error && this.state.projects.length !== 0) ?
                                         
                                        this.state.projects.map((item, cont) => {
                                                return(
                                                    <div key={cont} className="projectsList" style={{maxWidth: '80%', margin: 'auto'}} id={`singleobj${cont}`}>
                                                        <br/>
                                                          <div className="row" >
                                                            <div className="col" style={{maxWidth: '150px'}}><small>Title: </small></div>
                                                            <div className="col"><h4 onClick = {() => {this.showItem(item, cont)}}>{item.title}</h4></div>
                                                          </div>
                                                        <br/>
                                                        <div className="bg-gray-300 h-[0.5px]"></div>
                                                    </div>
                                                )
                                            })
                                        :
                                        <p className="text-center">No projects waiting for approval</p>
                                        }     
                                </div>
                    :
                    ''
                }

                <div>
                    <AdminNews />
                </div>

                <div>
                    <h1 className="font-bold text-center text-3xl mt-16">Internal Submissions</h1>
                    
                    <div className="border sm:px-10">
                        {this.state.submissions.length ? (
                        this.state.submissions.map(obj => (
                            <div className="sm:px-10" key={obj.id}>
                                <p onClick={() => this.openSubmission(obj.id)}>Request by </p>
                                <p onClick={() => this.openSubmission(obj.id)} className="text-xl">{obj.name}</p>
                                <p onClick={() => this.openSubmission(obj.id)}>{obj.email}</p>

                                {this.state.submissionOpened === obj.id && (
                                    <div>
                                        <br/>
                                        <p className="font-bold">Members requested information: </p>
                                        <p className="text-sm">{obj.memberInfo}</p>

                                        <br/>
                                        <p className="font-bold">Project title: </p>
                                        <p>{obj.projectTitle}</p>

                                        <br/>
                                        <p className="font-bold">Motivation: </p>
                                        <p>{obj.motivation}</p>

                                        <br/>
                                        <p className="font-bold">Do the external collaborators need access to the internal data?: </p>
                                        <p>{obj.comments}</p>

                                        <br/>
                                        <button onClick={() => this.approveSubmission(obj.id)} className="p-2 bg-green-500 rounded" >Approve</button>
                                        <button onClick={() => this.denySubmission(obj.id)} className="p-2 bg-rose-500 rounded">Deny</button>
                                    </div>
                                )}
                                
                                <div className="h-[0.25px] bg-gray-900"></div>
                                
                            </div>
                            
                            ))
                        ) : (
                            <p className="text-center">No submissions</p>
                        )}
                    </div>
                    
                </div>

                <div>
                    <h3 className="font-bold text-center text-3xl mt-16">Users Permissions</h3>
                    <p className="text-center text-rose-600">3+ - Permission to create project (view internal) // 3 - external // 4 - internal // 5 - admin</p>

                    <div className="m-auto w-[114px]">
                        <button className="border inline-flex items-center border-gray-900 rounded px-4 py-2" onClick={() => this.getUsers()}>Load users</button>
                    </div>
                    
                    {this.state.users ?
                        this.state.users.map(item => (
                            <div key={item.id}>
                                <div className="md:grid md:grid-cols-5 p-5">
                                    <div className="w-[200px] p-2" >{item.name}</div>
                                    <div className="w-[100px] p-2" >{item.email}</div>
                                    <div className="w-[100px] p-2" >{item.institution}</div>
                                    <div className="w-[10px] font-bold p-2" ><h4 id={`itemMember${item.id}`}>{item.member}</h4></div>
                                    <div className=""><input className="w-[50px] rounded" id={`permission${item.id}`} type="text"></input><button className="bg-indigo-100 p-2 rounded" onClick={() => this.changePermission(item.id)}>Change</button></div>
                                </div>
                                <div className="bg-gray-300 h-[0.5px]"></div>
                            </div>
                            ))
                        :
                        ''

                    }
                </div>
                    
                </div>
                
        );
    }
}


export default Admin
