import { useState, useContext, useEffect } from 'react'
import { AuthContext } from './AuthContext'
import { useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-toastify' 

export default function Login(){

	const [username, setUsername] = useState(null)
	const [password, setPassword] = useState(null)

	const con = useContext(AuthContext)
	const navigate = useNavigate()

	useEffect(() => {
		con.testUser()

		if(con.isAuth){
			navigate(-2)
		}
	}, [con.isAuth])
	
	const tryLogin = () => {

		if (!username || !password){

			toast.warn('Must fill all fields!')
		}
		else{
			con.login(username, password)
		}
	}

	return(
		<div className="space-y-6 sm:p-12 p-4 max-w-7xl m-auto">
			<img src="" alt=""/>

			<div className="bg-white shadow p-12 sm:rounded-lg sm:p-6">
		        <div className="md:grid md:grid-cols-3 md:gap-6">
		          <div className="md:col-span-1">
		            <h3 className="text-lg font-medium leading-6 text-gray-900">Login with your splus.cloud user</h3>
		            <p className="mt-1 text-sm text-gray-500">Loggin in gives you access to all splus available data.</p>
		          </div>
		          <div className="mt-5 md:mt-0 md:col-span-2">
		            <form action="#" method="POST">
		              <div className="grid grid-cols-6 gap-6">
		                <div className="col-span-6 sm:col-span-3">
		                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
		                    Username
		                  </label>
		                  <input
		                  	onChange={(e) => setUsername(e.target.value)}
		                    type="text"
		                    name="username"
		                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
		                  />
		                </div>

		                <div className="col-span-6 sm:col-span-3">
		                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
		                    Password
		                  </label>
		                  <input
		                  	onChange={(e) => setPassword(e.target.value)}
		                    type="password"
		                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
		                  />
		                </div>


		                	<div className="col-span-6 sm:col-span-2">
    							<div className="flex">
					                <button
					                	onClick = {() => tryLogin()}
							            type="button"
							            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							          >
							            Login
							         </button>
							    </div>
							</div>

							<div className="col-span-6 sm:col-span-2 place-content-center">
								<div className="py-2">
									<Link to="/register" className="text-center rounded-md text-sm font-medium text-sky-500">New here?</Link>
								</div>
							</div>

							<div className="col-span-6 sm:col-span-2 place-content-center">
								<div className="py-2">
									<Link to="/passreset" className="text-center rounded-md text-sm font-medium text-sky-500">Forgot password?</Link>
								</div>
							</div>

				               
						</div>
		            </form>
		          </div>
		        </div>
		      </div>
	    </div>
	)
}