import React, { Suspense, lazy } from 'react';
import axios from 'axios';
import { getToken } from '../auth/token'
import SpinLoad from "../common/Spinning"
import { Link } from 'react-router-dom';

if (process.env.NODE_ENV==="development"){
  var IP = 'https://splus.cloud'
}else{
  IP = ''
}

class ForMembers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            members: []
        }
    }

    componentDidMount(){
        var config = getToken()

        axios.get('/api/getMembers', config)
        .then(res => {
            this.setState({members: res.data})
        })

        this.opentab('Policies')
    }

    opentab(tabName) {
          var i;
          var x = document.getElementsByClassName("memberTab");
          for (i = 0; i < x.length; i++) {
            x[i].style.display = "none";
          }
          document.getElementById(tabName).style.display = "block";
    }

    render() {
        const Policies = lazy(() => import('./members/policies'));
        const ProjectList = lazy(() => import('./members/ProjectList'));

        const InternalRequests = lazy(() => import('./members/Internalrequest'));
        const VisualIdentity = lazy(() => import('./members/visual'));
        

        return (
            <div className="container m-auto"> 
                <div className="m-auto max-w-5xl mt-8 justify-around mb-16">
                  <button className="p-4 m-1 bg-indigo-100 rounded" onClick={() => this.opentab('Policies')}>Team Policies</button>
                  <button className="p-4 m-1 bg-indigo-100 rounded" onClick={() => this.opentab('InternalAccess')}>Internal Data Access</button>
                  <button className="p-4 m-1 bg-indigo-100 rounded" onClick={() => this.opentab('projects')}>List of projects</button>
                  <button className="p-4 m-1 bg-indigo-100 rounded" onClick={() => this.opentab('visual')}>Visual Identity</button>
                  
                </div>

                <div id="Policies" className="memberTab">
                    <Suspense fallback={<SpinLoad />}>
                        <Policies />
                    </Suspense>
                </div>

                <div id="InternalAccess" className="memberTab text-center" style={{display:'none'}}>
                  <Suspense fallback={<SpinLoad />}>
                        <InternalRequests />
                    </Suspense>
                </div>

                <div className="memberTab" id="projects">
                    <Suspense fallback={<SpinLoad />}>
                        <ProjectList />
                    </Suspense>    
                </div>

                <div className="memberTab" id="visual">
                    <Suspense fallback={<SpinLoad />}>
                        <VisualIdentity />
                    </Suspense>    
                </div>


            </div>

        );
    }
}


export default ForMembers
