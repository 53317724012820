import React from 'react'
import axios from 'axios'
import { getToken } from '../../auth/token';

if (process.env.NODE_ENV==="development"){
  console.log("dev")
  var IP = 'https://splus.cloud'
}else{
  IP = ''
}

class ResultsTab extends React.Component {
  isComponentMounted: boolean = false;
  
  constructor(props) {
    super(props)

    this.state = {
      status: props.status,
      processingTime: null
    }

    this.clockTimer = -1
    this.statusTimer = -1

    //this.api = new API()

    this.stopTimers = this.stopTimers.bind(this)
  }

  componentDidMount() {
    this.isComponentMounted = true;
    if (!this.props.status){
      return(<div></div>)
    }

    var config = getToken()
    if (this.props.execMode === 'async'
      && this.props.status
      && this.props.status.phase !== 'COMPLETED'
      && this.props.status.phase !== 'ERROR')
      {
      this.statusTimer = setInterval(() => {
        axios.get(`${this.props.link}/async/${this.state.status.jobId}`, {headers: { 'Authorization': config.headers['Authorization'] }})
          .then(status => {
            console.log(status)
            console.log(status.data.phase)
            if (status.data.phase === 'COMPLETED' || status.data.phase === 'ERROR') this.stopTimers()
            this.setState({ status: status.data })
          }).catch(console.log)
      }, 4000)
  
      this.clockTimer = setInterval(() => {
        const time = new Date(new Date() - new Date(this.state.status.startTime)).toISOString().substr(11, 8)
        this.setState({ processingTime: time })
      }, 1000)
    }

    const query = this.state.status.parameters.query
    const queryid = `${this.props.link}/async/${this.state.status.jobId}`
    const body = JSON.stringify({ query, queryid })

    console.log(body)
    if (this.props.execMode === 'async' && this.props.status.phase !== 'ERROR'){
      axios.post(`${IP}/api/track`, body,{headers: { 'Authorization': config.headers['Authorization'], 'Content-Type': 'application/json'}})
        .then(status => {
      }).catch(console.log)
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    this.stopTimers()
  }

  stopTimers() {
    if (this.clockTimer > 0) {
      clearInterval(this.clockTimer)
      this.clockTimer = -1
    }

    if (this.statusTimer > 0) {
      clearInterval(this.statusTimer)
      this.statusTimer = -1
    }
  }

  getColoredProgess(progress) {
    switch (progress) {
      case 'COMPLETED':
        return (<span className="text-lime-900 font-bold">COMPLETED</span>)
      case 'ERROR':
        return (<span className="text-myred font-bold">ERROR</span>)
      case 'EXECUTING':
        return (<span className="text-amber-800 font-bold">EXECUTING</span>)
      default:
        return (<span className="font-bold">{progress}</span>)
    }
  }

  render() {

    const s = this.state.status
    if (!s) return <> </>
    const results = Array.isArray(s.results) && s.results.length > 0 ? (
      <div className="mt-6">
        <h5 className="text-myblue">Results</h5>
        <dl className="grid grid-cols-2">
          <dt className="col-3">Size</dt>
          <dd className="col-9">{s.results[0].size / 1000} kB</dd>
          <dt className="col-3">Output</dt>
          <dd className="col-9">
            <a href={(() => {
              // const url = new URL(s.results[0].href.replace(':8080', '').replace('http', 'https'))
              // url.hostname = 'splus.cloud'
              const url = new URL(s.results[0].href.replace(':8080', '').replace('http', 'https'))
              url.hostname = 'splus.cloud'

              return url
              
            })()}><button className="inline-flex bg-gradient-to-r from-myred to-myyellow text-black bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-black hover:from-purple-700 hover:to-indigo-700 hover:text-white">Download</button></a>
          </dd>
        </dl>
      </div>
    ) : ''

    return (
      <div className="max-w-5xl m-auto sm:pl-5 py-10">
        <h5 className="font-bold py-4">Request Info</h5>
        <dl className="grid grid-cols-2">
          <dt className="col-span-1">Progress</dt>
          <dd className="col-span-1">{this.getColoredProgess(s.phase)}</dd>
          <dt className="col-3">Creation Time</dt>
          <dd className="col-9">{new Date(s.creationTime).toLocaleString()}</dd>
          <dt className="col-3">Start Time</dt>
          <dd className="col-9">{new Date(s.startTime).toLocaleString()}</dd>
          <dt className="col-3">End Time</dt>
          <dd className="col-9">{s.endTime ? new Date(s.endTime).toLocaleString() : '-'}</dd>
          <dt className="col-3">Processing Time</dt>
          <dd className="col-9">{this.state.processingTime ? this.state.processingTime : '-'}</dd>
          <dt className="col-3">Query</dt>
          <dd className="col-9">{s.parameters.query}</dd>
          <dt className="col-3">Language</dt>
          <dd className="col-9">{s.parameters.lang}</dd>
          <dt className="col-3">Output Format</dt>
          <dd className="col-9">{s.parameters.format}</dd>
        </dl>

        {results}
        {s.error.message && <small className="text-myred"> Error message: {s.error.message}</small>}
      </div>
    )
  }
}

export default ResultsTab

// {"phase":"COMPLETED",
// "jobId":"1607891387988",
// "creationTime":"2020-12-13T20:29:47Z",
// "startTime":"2020-12-13T20:29:47Z",
// "endTime":"2020-12-13T20:29:48Z",
// "executionDuration":0,
// "error":{},
// "version":"1.1","
// parameters":{"request":"doQuery","maxRec":-1,"query":"select top 10 * from idr3.r_band; ","format":"votable","lang":"ADQL","version":"1.0"},
// "results":[{"mime-type":"application/x-votable+xml","size":4637,"id":"result","href":"http://192.168.0.110:8080/tap/tap/async/1607891387988/results/result","type":"simple","redirection":false}]}
